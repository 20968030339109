<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :title="`Edit ${report ? report.type.replace('-', ' ') : ''}`"
    ok-text="Save"
    @ok="handleUpdateUserReport"
    @cancel="$emit('close')"
  >
    <a-form
      v-if="report && report.type === ReportType.TIME"
      layout="horizontal"
      :data-vv-scope="ReportType.TIME"
    >
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('time-report.role_id') ? 'error' : ''"
        :help="$validator.errors.first('time-report.role_id')"
        label="Project role"
      >
        <a-select
          v-model="updatedReport.role_id"
          v-validate="'required'"
          :default-value="updatedReport.role_id"
          show-search
          option-filter-prop="children"
          placeholder="Select a project role"
          data-vv-name="role_id"
          data-vv-as="project role"
        >
          <a-select-option v-for="r in userRoles" :key="r.id" :value="r.id">
            {{ `${r.title} (${r.project.name})` }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('time-report.amount') ? 'error' : ''"
        :help="$validator.errors.first('time-report.amount')"
        label="Amount of hours"
      >
        <a-input-number
          v-model="updatedReport.amount"
          v-validate="'required|integer|max:64'"
          :min="0"
          data-vv-name="amount"
          placeholder="Hours worked"
          class="w-100"
        />
      </a-form-item>

      <SkInput
        v-model="updatedReport.hourly_rate_cents"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('time-report.hourly_rate_cents')"
        :addon-after="updatedReport.hourly_rate_currency"
        data-vv-name="hourly_rate_cents"
        data-vv-as="hourly rate"
        label="Hourly rate"
        placeholder="Hourly rate"
        @set-currency="(v) => (updatedReport.rate_currency = v)"
      />

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('time-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('time-report.start_date')"
        label="Date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.start_date)"
          :disabled-date="disabledDateBeforeLastLock"
          data-vv-name="start_date"
          data-vv-as="date"
          class="w-100"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('time-report.cost_center_id') ? 'error' : ''"
        :help="$validator.errors.first('time-report.cost_center_id')"
        label="Cost center"
      >
        <a-select
          v-model="updatedReport.cost_center_id"
          v-validate="'required'"
          placeholder="Select a cost center"
          data-vv-name="cost_center_id"
          data-vv-as="cost center"
        >
          <a-select-option v-for="c in costCentersFiltered" :key="c.id" :value="c.id">
            {{ c.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="updatedReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>

      <a-row v-if="loadingSubprojects" type="flex" justify="center" :gutter="16">
        <a-col><a-icon type="loading" /></a-col>
        <a-col><span>Loading subprojects...</span></a-col>
      </a-row>
      <a-form-item v-else-if="subprojectsCollection.length">
        <a-row :gutter="[48, 16]">
          <a-col :span="12">Categorize your time:</a-col>
          <a-col :span="12" style="text-align: right">Hours worked</a-col>
        </a-row>
        <a-row v-for="sp in subprojectsCollection" :key="sp.id">
          <a-col :span="18">
            <a-row type="flex" justify="start">
              <a-checkbox v-model="sp.isChecked" :value="sp.name">{{ sp.name }}</a-checkbox>
            </a-row>
          </a-col>
          <a-col :span="6">
            <a-row type="flex" justify="end">
              <a-input-number
                v-model="sp.amount"
                :disabled="!sp.isChecked"
                :min="0"
                :max="updatedReport.amount"
              />
            </a-row>
          </a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <span v-if="hoursLeft >= 0"
            ><b>{{ hoursLeft }}h</b> remaining</span
          >
          <a-alert
            v-else
            type="warning"
            show-icon
            class="mt-2"
            :message="`You have categorized ${-hoursLeft} hours too many.`"
          />
        </a-row>
      </a-form-item>
    </a-form>

    <a-form
      v-if="report && report.type === ReportType.VACATION"
      layout="horizontal"
      :data-vv-scope="ReportType.VACATION"
    >
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('vacation-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('vacation-report.start_date')"
        label="Start date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.start_date)"
          :disabled-date="handleDisabledStartDates"
          :disabled="
            new Date(report.start_date) < new Date() && !baseAcl.isAdmin && !baseAcl.isFinancial
          "
          class="w-100"
          data-vv-name="start_date"
          data-vv-as="start date"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('vacation-report.end_date') ? 'error' : ''"
        :help="$validator.errors.first('vacation-report.end_date')"
        label="End date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.end_date)"
          :disabled-date="handleDisabledEndDates"
          class="w-100"
          data-vv-name="end_date"
          data-vv-as="end date"
          @change="(d) => onDatesChange(d, 'end_date')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="updatedReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>
    </a-form>

    <a-form
      v-if="report && report.type === ReportType.ABSENCE"
      layout="horizontal"
      :data-vv-scope="ReportType.ABSENCE"
    >
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('absence-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('absence-report.start_date')"
        label="Start date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.start_date)"
          :disabled-date="handleDisabledStartDates"
          :disabled="
            new Date(report.start_date) < new Date() && !baseAcl.isAdmin && !baseAcl.isFinancial
          "
          class="w-100"
          data-vv-name="start_date"
          data-vv-as="start date"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('absence-report.end_date') ? 'error' : ''"
        :help="$validator.errors.first('absence-report.end_date')"
        label="End date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.end_date)"
          :disabled-date="handleDisabledEndDates"
          class="w-100"
          data-vv-name="end_date"
          data-vv-as="end date"
          @change="(d) => onDatesChange(d, 'end_date')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="updatedReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>
    </a-form>

    <a-form
      v-if="report && report.type === ReportType.SICK"
      layout="horizontal"
      :data-vv-scope="ReportType.SICK"
    >
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('sick-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('sick-report.start_date')"
        label="Date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.start_date)"
          class="w-100"
          data-vv-name="start_date"
          data-vv-as="start date"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="VAB">
        <a-checkbox v-model="updatedReport.vab" />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="updatedReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>
    </a-form>

    <a-form
      v-if="report && report.type === ReportType.PARENTAL"
      layout="horizontal"
      :data-vv-scope="ReportType.PARENTAL"
    >
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('parental-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('parental-report.start_date')"
        label="Start date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.start_date)"
          :disabled-date="handleDisabledStartDates"
          :disabled="
            new Date(report.start_date) < new Date() && !baseAcl.isAdmin && !baseAcl.isFinancial
          "
          class="w-100"
          data-vv-name="start_date"
          data-vv-as="start date"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('parental-report.end_date') ? 'error' : ''"
        :help="$validator.errors.first('parental-report.end_date')"
        label="End date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.end_date)"
          :disabled-date="handleDisabledEndDates"
          class="w-100"
          data-vv-name="end_date"
          data-vv-as="end date"
          @change="(d) => onDatesChange(d, 'end_date')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Percentage">
        <a-radio-group
          :default-value="'1'"
          :value="updatedReport.parental_percentage"
          @change="(el) => (updatedReport.parental_percentage = el.target.value)"
        >
          <a-radio :value="'0.25'">25%</a-radio>
          <a-radio :value="'0.5'">50%</a-radio>
          <a-radio :value="'0.75'">75%</a-radio>
          <a-radio :value="'1'">100%</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="updatedReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>
    </a-form>

    <a-form
      v-if="report && report.type === ReportType.DETACHEDTIME"
      layout="horizontal"
      :data-vv-scope="ReportType.DETACHEDTIME"
    >
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('detached-time-report.amount') ? 'error' : ''"
        :help="$validator.errors.first('detached-time-report.amount')"
        label="Amount of hours"
      >
        <a-input-number
          v-model="updatedReport.amount"
          v-validate="'required|integer|min_value:1|max_value:24'"
          :min="0"
          data-vv-name="amount"
          placeholder="Hours worked"
          class="w-100"
        />
      </a-form-item>

      <SkInput
        v-model="updatedReport.hourly_rate_cents"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('detached-time-report.hourly_rate_cents')"
        :addon-after="updatedReport.hourly_rate_currency"
        data-vv-name="hourly_rate_cents"
        data-vv-as="hourly rate"
        label="Hourly rate"
        placeholder="Hourly rate"
        @set-currency="(v) => (updatedReport.rate_currency = v)"
      />

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('detached-time-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('detached-time-report.start_date')"
        label="Date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.start_date)"
          :disabled-date="disabledDateBeforeLastLock"
          data-vv-name="start_date"
          data-vv-as="date"
          class="w-100"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="
          $validator.errors.first('detached-time-report.cost_center_id') ? 'error' : ''
        "
        :help="$validator.errors.first('detached-time-report.cost_center_id')"
        label="Cost center"
      >
        <a-select
          v-model="updatedReport.cost_center_id"
          v-validate="'required'"
          placeholder="Select a cost center"
          data-vv-name="cost_center_id"
          data-vv-as="cost center"
        >
          <a-select-option v-for="c in costCentersFiltered" :key="c.id" :value="c.id">
            {{ c.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Comment"
        :validate-status="$validator.errors.first('detached-time-report.comment') ? 'error' : ''"
        :help="$validator.errors.first('detached-time-report.comment')"
      >
        <a-textarea
          v-model="updatedReport.comment"
          v-validate="'required'"
          :auto-size="{ minRows: 2 }"
          data-vv-name="comment"
          data-vv-as="comment"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, watch, onUnmounted, onMounted } from "vue";
import { ProfileInterface, BaseAclInterface } from "../../authentication/types";
import { disabledStartDates, disabledEndDates } from "../_utils/disabled-dates";
import { CostCenterInterface, CostCenterUseCase } from "@/modules/cost_centers/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import {
  ReportInterface,
  ReportPayloadInterface,
  ReportType,
  SubprojectReportInterface,
} from "../types";
import { apiGetSubprojects } from "@/modules/projects/_utils/api";
import { RoleInterface } from "../../roles/types";
import useMixin from "@/useMixin";
import { Moment } from "moment";
import moment from "@/date";
import useFilters from "@/useFilters";
import { emitter } from "@/mitt";

// Props
const props = defineProps({
  userVacationAndAbsenceReports: { type: Array as () => Array<ReportInterface>, default: () => [] },
  userParentalReports: { type: Array as () => Array<ReportInterface>, default: () => [] },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  userRoles: { type: Array as () => Array<RoleInterface>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  expenseReport: { type: Object, default: undefined },
  report: { type: Object, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["get-cost-centers", "update-report", "close"]);

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;
const $confirm = instance?.proxy.$confirm;
const $message = instance?.proxy?.$message;

// Computed properties
const costCentersFiltered = computed(() => {
  if (props.report.type === ReportType.DETACHEDTIME) {
    return props.costCenters.filter((c) => c.use_cases.includes(CostCenterUseCase.DETACHEDTIME));
  } else {
    return props.costCenters;
  }
});

// Data properties
const updatedReport = ref<any>({
  id: undefined,
  type: undefined,
  user_id: undefined,
  role_id: undefined,
  amount: undefined,
  hourly_rate_cents: undefined,
  hourly_rate_currency: undefined,
  date: undefined,
  start_date: undefined,
  end_date: undefined,
  cost_center_id: undefined,
  vab: undefined,
  parental_percentage: undefined,
  comment: undefined,
  suppress_warnings: undefined,
  subproject_reports: undefined,
});
const hoursLeft = computed(() => {
  let amount;
  if (typeof updatedReport.value.amount === "number") {
    amount = updatedReport.value.amount;
  } else {
    amount = 0;
  }
  return (
    amount -
    subprojectsCollection.value
      .map((sp, i) => (sp.isChecked ? sp.amount : 0))
      .reduce((a, b) => a + b, 0)
  );
});
interface SubprojectCollection {
  id: number;
  name: string;
  isChecked: boolean;
  amount: number;
}

const loadingSubprojects = ref(true);
const subprojectsCollection = ref<Array<SubprojectCollection>>([]);

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Initialize all fields
      updatedReport.value = setObject(updatedReport.value, props.report);
      updatedReport.value.user_id = props.report.user.id;
      updatedReport.value.hourly_rate_cents = props.report.hourly_rate
        ? props.report.hourly_rate.value / 100
        : 0;
      updatedReport.value.hourly_rate_currency = props.report.hourly_rate
        ? props.report.hourly_rate.currency_code
        : "SEK";
      updatedReport.value.role_id = props.report.role ? props.report.role.id : undefined;
      updatedReport.value.cost_center_id = props.report.cost_center
        ? props.report.cost_center.id
        : undefined;
      if (updatedReport.value.suppress_warnings) {
        updatedReport.value.suppress_warnings = undefined;
      }
      updateSubprojects();
    }
  }
);

// Life-cycle Hooks
onMounted(() => {
  emitter.on("show-prepaid-update-warning", (data) =>
    showConfirmPrepaidModal(
      (data as { report: ReportPayloadInterface; warning: string })
        .report as ReportPayloadInterface,
      (data as { report: ReportPayloadInterface; warning: string }).warning as string
    )
  );
  emits("get-cost-centers");
});

onUnmounted(() => {
  emitter.off("show-prepaid-update-warning", (data) =>
    showConfirmPrepaidModal(
      (data as { report: ReportPayloadInterface; warning: string })
        .report as ReportPayloadInterface,
      (data as { report: ReportPayloadInterface; warning: string }).warning as string
    )
  );
});

// Component methods
const changeHourlyRateToCents = (): void => {
  updatedReport.value.hourly_rate_cents = toMoneyCents(updatedReport.value.hourly_rate_cents);
};

const handleUpdateUserReport = (): void => {
  if (subprojectsCollection.value.length > 0) {
    updatedReport.value.subproject_reports = subprojectsCollection.value
      .filter((sp) => sp.isChecked && sp.amount > 0)
      .map((sp) => ({
        subproject_id: sp.id,
        amount: sp.amount,
      }));
  }
  $validator?.validateAll(props.report.type).then((result) => {
    if (result) {
      changeHourlyRateToCents();
      emits("update-report", updatedReport.value);
      emits("close");
    }
  });
};

const updateSubprojects = async (): Promise<void> => {
  loadingSubprojects.value = true;
  subprojectsCollection.value = [];
  if (props.report && props.report.project) {
    const projectId = props.report.project.id;
    const subreports = props.report.subproject_reports;
    try {
      const subprojectsRequest = await apiGetSubprojects(projectId);
      const subprojects = subprojectsRequest.data.data;
      subprojectsCollection.value = subprojects.map((sp: { id: number; name: string }) => {
        const subreport = subreports.find(
          (sr: SubprojectReportInterface) => sr.subproject_id === sp.id
        );
        return {
          id: sp.id,
          name: sp.name,
          isChecked: subreport ? true : false,
          amount: subreport ? subreport.amount : 0,
        };
      });
    } catch {
      $message?.error("Failed to fetch subprojects");
    } finally {
      loadingSubprojects.value = false;
    }
  }
};

const onDatesChange = (date: any, attribute: string): void => {
  if (date === null) {
    updatedReport.value[attribute] = null;
  } else {
    updatedReport.value[attribute] = date.format("YYYY-MM-DD");
  }
};

const handleDisabledStartDates = (current: Moment): boolean => {
  return disabledStartDates(
    current,
    updatedReport.value,
    updatedReport.value.type === ReportType.PARENTAL
      ? props.userParentalReports
      : props.userVacationAndAbsenceReports,
    canEditPastDates()
  );
};

const handleDisabledEndDates = (current: Moment): boolean => {
  return disabledEndDates(
    current,
    updatedReport.value,
    updatedReport.value.type === ReportType.PARENTAL
      ? props.userParentalReports
      : props.userVacationAndAbsenceReports,
    canEditPastDates()
  );
};

const canEditPastDates = (): boolean => {
  return baseAcl.value.isAdmin || baseAcl.value.isFinancial;
};

const disabledDateBeforeLastLock = (current: any): boolean => {
  if (baseAcl.value.isAdmin || baseAcl.value.isFinancial) {
    return false;
  }

  return disabledDateBefore(current, props.user.report_locking_date);
};

const disabledDateBefore = (current: any, before_date: string): boolean => {
  return current && current <= moment(before_date).endOf("day");
};

const showConfirmPrepaidModal = (report: ReportPayloadInterface, warning: string): void => {
  $confirm?.({
    title: "Not enough available vacation days",
    content: warning,
    okText: "Continue",
    onOk() {
      updatedReport.value = report;
      updatedReport.value.suppress_warnings = true;
      handleUpdateUserReport();
    },
  });
};
</script>
