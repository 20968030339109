<template>
  <div>
    <div v-show="filterPanelVisible" class="card mb-3">
      <a-form layout="vertical">
        <bookmarked-filters-selection
          :bookmarked-filters="bookmarkedFilters"
          :filter-is-empty="filterIsEmpty"
          bookmark-type="Roles"
          class="ml-3"
          @selected="(bookmarkFilterUrl) => handleCustomFilterSelected(bookmarkFilterUrl)"
          @create-bookmark-filter="handleCreateBookmarkFilter"
          @update-bookmark-filter="handleUpdateBookmarkFilter"
          @delete-bookmark-filter="handleDeleteBookmarkFilter"
        />
        <a-row>
          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.user_id"
              label="Users"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select users"
              :allow-clear="true"
              :options="usersCompactList"
              :disabled="unchangeableFilter && unchangeableFilter.user_id !== undefined"
              @change="applyFilters"
            />
          </a-col>

          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.client_id"
              label="Clients"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select clients"
              :allow-clear="true"
              :options="clientsCompactList"
              :disabled="unchangeableFilter && unchangeableFilter.client_id !== undefined"
              @change="applyFilters"
            />
          </a-col>

          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.project_id"
              label="Projects"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select projects"
              :allow-clear="true"
              :options="projectsCompactList"
              :disabled="unchangeableFilter && unchangeableFilter.project_id !== undefined"
              @change="applyFilters"
            />
          </a-col>

          <a-col :lg="11" class="ml-3">
            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Description status"
            >
              <a-select
                v-model="newFilter.role_description_status"
                allow-clear
                placeholder="Select description status"
                :disabled="
                  unchangeableFilter && unchangeableFilter.role_description_status !== undefined
                "
                @change="() => applyFilters()"
              >
                <a-select-option :value="RoleDescriptionStatus.MISSING">
                  <a-tag :color="Colors.orange">Missing</a-tag>
                </a-select-option>
                <a-select-option :value="RoleDescriptionStatus.PENDING">
                  <a-tag :color="Colors.yellow">Pending</a-tag>
                </a-select-option>
                <a-select-option :value="RoleDescriptionStatus.PRESENT">
                  <a-tag :color="Colors.green">Present</a-tag>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :lg="11" class="ml-3">
            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Date"
              class="pb-0 mb-0"
            >
              <a-date-picker
                :value="parseDate(newFilter.start_date)"
                :disabled-date="(current) => disabledStartDate(current)"
                placeholder="Start Date"
                class="w-100 mb-2"
                :disabled="unchangeableFilter && unchangeableFilter.start_date !== undefined"
                @change="(date) => onDateChange(date, 'start_date')"
              />
              <a-date-picker
                :value="parseDate(newFilter.end_date)"
                :disabled-date="(current) => disabledEndDate(current)"
                placeholder="End Date"
                class="w-100 mb-4"
                :disabled="unchangeableFilter && unchangeableFilter.end_date !== undefined"
                @change="(date) => onDateChange(date, 'end_date')"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="11" class="ml-3">
            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Extension status"
              class="mb-2"
            >
              <a-select
                v-model="newFilter.extension_statuses"
                mode="multiple"
                allow-clear
                placeholder="Select extension status"
                :disabled="
                  unchangeableFilter && unchangeableFilter.extension_statuses !== undefined
                "
                @change="() => applyFilters()"
              >
                <a-select-option value="to_be_discussed">
                  <a-tag
                    :color="extensionStatusColor[ExtensionStatus.TO_BE_DISCUSSED]"
                    class="default-cursor"
                  >
                    To be discussed
                  </a-tag>
                </a-select-option>
                <a-select-option value="waiting">
                  <a-tag
                    :color="extensionStatusColor[ExtensionStatus.WAITING]"
                    class="default-cursor"
                  >
                    Waiting
                  </a-tag>
                </a-select-option>
                <a-select-option value="approved">
                  <a-tag
                    :color="extensionStatusColor[ExtensionStatus.APPROVED]"
                    class="default-cursor"
                  >
                    Approved
                  </a-tag>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="11" class="ml-3">
            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Inactive users"
              class="pt-0 mt-0"
            >
              <a-checkbox v-model="newFilter.inactive_users" @change="() => applyFilters()">
                Include
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-row class="d-flex justify-content-end">
        <span>
          <strong>Results:</strong>
          {{ numberOfResults }}
        </span>
      </a-row>
    </div>
    <portal to="roles-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance } from "vue";
import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import { getUsersCompactList, getClientsCompactList, getProjectsCompactList } from "@/api";
import { ExtensionStatus, extensionStatusColor } from "../_utils/role-extension";
import { BookmarkedFilterInterface } from "@/modules/bookmarked_filters/types";
import { RoleDescriptionStatus, RolesFilterInterface } from "../types";
import { MinimalProjectInterface } from "@/modules/projects/types";
import { MinimalClientInterface } from "@/modules/clients/types";
import { ProfileInterface } from "../../authentication/types";
import { MinimalUserInterface } from "@/modules/users/types";
import Colors from "@/assets/scss/_colors.module.scss";
import useMixin from "@/useMixin";
import moment from "@/date";
import {
  apiGetUserBookmarkedFilters,
  apiStoreUserBookmarkedFilter,
  apiUpdateUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
} from "@/modules/bookmarked_filters/_utils/api";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  numberOfResults: { type: Number, default: 0 },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  filters: { type: Object as () => RolesFilterInterface, default: undefined },
  unchangeableFilter: { type: Object as () => RolesFilterInterface, default: undefined },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { qsDecode, setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy?.$message;

// Data properties
const filterPanelVisible = ref<boolean>(false);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const projectsCompactList = ref<Array<MinimalProjectInterface>>([]);
const clientsCompactList = ref<Array<MinimalClientInterface>>([]);
const newFilter = ref<RolesFilterInterface>({
  user_id: [],
  client_id: [],
  project_id: [],
  start_date: undefined,
  end_date: undefined,
  role_description_status: undefined,
  inactive_users: undefined,
  extension_statuses: undefined,
});
const bookmarkedFilters = ref<Array<BookmarkedFilterInterface>>([]);

// Computed properties
const filterIsEmpty = computed((): boolean => {
  return !!(
    newFilter.value.user_id &&
    newFilter.value.user_id.length === 0 &&
    newFilter.value.project_id &&
    newFilter.value.project_id.length === 0 &&
    newFilter.value.client_id &&
    newFilter.value.client_id.length === 0 &&
    newFilter.value.start_date === undefined &&
    newFilter.value.end_date === undefined &&
    newFilter.value.role_description_status === undefined &&
    newFilter.value.inactive_users === undefined &&
    newFilter.value.extension_statuses === undefined
  );
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetUsersCompactList();
    handleGetProjectsCompactList();
    handleGetClientsCompactList();
    handleGetBookmarkedFilters();
    setFilter(props.filters);
  }
});

// Component methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleCustomFilterSelected = (bookmarkFilterUrl: string): void => {
  handleClearRolesFilter();
  bookmarkFilterUrl && typeof bookmarkFilterUrl == "string"
    ? emits("apply-filters", qsDecode(bookmarkFilterUrl).filters)
    : applyFilters();
};

const applyFilters = (): void => {
  if (!newFilter.value.inactive_users) newFilter.value.inactive_users = undefined;

  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  handleClearRolesFilter();
  emits("apply-filters", {});
};

const disabledStartDate = (startValue: any): boolean => {
  const endValue = moment(newFilter.value.end_date).endOf("day");
  if (!startValue || !endValue) return false;
  return startValue.valueOf() >= endValue.valueOf();
};

const disabledEndDate = (endValue: any): boolean => {
  const startValue = moment(newFilter.value.start_date);
  if (!endValue || !startValue) return false;
  return startValue.valueOf() >= endValue.valueOf();
};

const onDateChange = (date: any, type: string): void => {
  if (type === "start_date") {
    newFilter.value.start_date = date == null ? undefined : date.format("YYYY-MM-DD");
  } else if (type === "end_date") {
    newFilter.value.end_date = date == null ? undefined : date.format("YYYY-MM-DD");
  }
  applyFilters();
};

const handleClearRolesFilter = (): void => {
  newFilter.value = {
    user_id: [],
    client_id: [],
    project_id: [],
    start_date: undefined,
    end_date: undefined,
    role_description_status: undefined,
    inactive_users: undefined,
    extension_statuses: undefined,
  };
  newFilter.value = { ...props.unchangeableFilter };
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => {
    usersCompactList.value = users;
  });
};

const handleGetClientsCompactList = (): void => {
  getClientsCompactList().then((clients) => {
    clientsCompactList.value = clients;
  });
};

const handleGetProjectsCompactList = (): void => {
  getProjectsCompactList().then((projects) => {
    projectsCompactList.value = projects;
  });
};

const setFilter = (filter: RolesFilterInterface): void => {
  if (filter === undefined) return;
  newFilter.value = setObject(newFilter.value, filter);
};

const handleGetBookmarkedFilters = (): void => {
  apiGetUserBookmarkedFilters(props.profile.id).then(
    ({ data }: any) => (bookmarkedFilters.value = data.data)
  );
};

const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f: any) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number) => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter(
          (f: any) => f.id !== bookmarkFilterId
        ))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
