import axios from "@/network";
import { RoleInterface } from "@/modules/roles/types";
import {
  ProjectPayloadInterface,
  MinimalRepositoryInterface,
  MinimalMilestoneInterface,
  SubprojectInterface,
} from "../types";
import { RoleDescriptionFormInterface } from "@/modules/roles/types";
import { AxiosPromise } from "axios";

export function apiGetProjects(params: any, headers = {}) {
  const url = "/projects";
  return axios.get(url, { params, headers });
}

export function apiGetProject(projectId: number) {
  const url = `/projects/${projectId}`;
  return axios.get(url);
}

export function apiStoreProject(newProject: ProjectPayloadInterface) {
  const url = "/projects";
  const payload = { project: newProject };
  return axios.post(url, payload);
}

export function apiUpdateProject(projectId: number, updatedProject: ProjectPayloadInterface) {
  const url = `/projects/${projectId}`;
  const payload = { project: updatedProject };
  return axios.patch(url, payload);
}

export function apiDestroyProject(projectId: number) {
  const url = `/projects/${projectId}`;
  return axios.delete(url);
}

export function apiGetProjectRoles(projectId: number) {
  const url = `/projects/${projectId}/roles`;
  return axios.get(url);
}

export function apiStoreProjectRole(projectId: number, newRole: RoleInterface) {
  const url = `/projects/${projectId}/roles`;
  const payload = { role: newRole };
  return axios.post(url, payload);
}

export function apiUpdateProjectRole(
  projectId: number,
  roleId: number,
  updatedRole: RoleInterface
) {
  const url = `/projects/${projectId}/roles/${roleId}`;
  const payload = { role: updatedRole };
  return axios.patch(url, payload);
}

export function apiDestroyProjectRole(projectId: number, roleId: number) {
  const url = `/projects/${projectId}/roles/${roleId}`;
  return axios.delete(url);
}

export function apiGetProjectRepositories(projectId: number) {
  const url = `/projects/${projectId}/repositories`;
  return axios.get(url);
}

export function apiAddProjectRepository(projectId: number, repository: MinimalRepositoryInterface) {
  const url = `/projects/${projectId}/repositories`;
  const payload = { repository };
  return axios.post(url, payload);
}

export function apiRemoveProjectRepository(projectId: number, repositoryId: number) {
  const url = `/projects/${projectId}/repositories/${repositoryId}`;
  return axios.delete(url);
}

export function apiGetRepositories() {
  const url = `/projects/repositories`;
  return axios.get(url);
}

export function apiCreateMilestone(projectId: number, milestone: MinimalMilestoneInterface) {
  const url = `/projects/${projectId}/milestones`;
  const payload = {
    title: milestone.title,
    description: milestone.description,
    repositories: milestone.repositories,
    due_on: milestone.due_on,
  };
  return axios.post(url, payload);
}

export function apiGetAllSubprojects(
  projectIds?: number[],
  startDate?: Date,
  endDate?: Date,
  active?: boolean
): AxiosPromise<{ data: Array<SubprojectInterface> }> {
  const url = `/subprojects`;
  const params = {
    project_id: projectIds,
    start_date: startDate,
    end_date: endDate,
    active: active,
  };
  return axios.get(url, { params });
}

export function apiGetSubprojects(
  projectId: number
): AxiosPromise<{ data: Array<SubprojectInterface> }> {
  const url = `/projects/${projectId}/subprojects`;
  return axios.get(url);
}

export function apiGetRoleDescriptionFormAnswers(role_id: number) {
  const url = `/roles/${role_id}/role_description_forms?limit=1`;
  return axios.get(url);
}

export function apiSendRoleDescriptionForm(
  newRoleDescrptionForm: RoleDescriptionFormInterface
): any {
  const url = `/roles/${newRoleDescrptionForm.role_id}/role_description_forms`;
  return axios.post(url, newRoleDescrptionForm);
}

export function apiGetSubproject(projectId: number, subprojectId: number) {
  const url = `/projects/${projectId}/subprojects/${subprojectId}`;
  return axios.get(url);
}

export function apiStoreSubproject(subproject: SubprojectInterface, projectId: number) {
  const url = `/projects/${projectId}/subprojects`;
  const payload = { subproject: subproject };
  return axios.post(url, payload);
}
export function apiUpdateSubproject(
  subproject: SubprojectInterface,
  projectId: number,
  subprojectId: number
) {
  const url = `/projects/${projectId}/subprojects/${subprojectId}`;
  const payload = { subproject: subproject };
  return axios.patch(url, payload);
}

export function apiDestroySubproject(projectId: number, subprojectId: number) {
  const url = `/projects/${projectId}/subprojects/${subprojectId}`;
  return axios.delete(url);
}
