<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Create Role Extension"
    ok-text="Save"
    @ok="handleCreateRoleExtension"
    @cancel="$emit('close')"
  >
    <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="User">
      <strong v-if="role">{{ role.user.name }}</strong>
    </a-form-item>

    <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Role">
      <strong v-if="role">{{ role.title }}</strong>
    </a-form-item>

    <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Project">
      <strong v-if="role">{{ role.project.name }}</strong>
    </a-form-item>

    <a-form layout="horizontal">
      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item label="Current end date">
            <strong v-if="role">{{ role.end_date }}</strong>
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('new_end_date') ? 'error' : ''"
            :help="$validator.errors.first('new_end_date')"
            label="New end date"
          >
            <a-date-picker
              v-validate="'required'"
              :value="newRoleExtension.end_date"
              :disabled-date="isDisabledDate"
              class="w-100"
              data-vv-name="new_end_date"
              data-vv-as="new end date"
              @change="(d) => (newRoleExtension.end_date = d?.format('YYYY-MM-DD'))"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item label="Current hourly rate">
            <strong v-if="role">
              {{ role.hourly_rate?.formatted_value }} {{ role.hourly_rate?.currency_code }}
            </strong>
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <SkMoney
            v-model="newHourlyRateInput"
            v-validate="'required|decimal|min_value:0'"
            label="New hourly rate"
            :label-col="{ span: 25 }"
            :wrapper-col="{ span: 24 }"
            :error="$validator.errors.first('new_hourly_rate')"
            :currency="newRoleExtension.hourly_rate_currency"
            data-vv-name="new_hourly_rate"
            data-vv-as="new hourly rate"
            placeholder="Hourly rate"
            @set-currency="(v) => (newRoleExtension.hourly_rate_currency = v)"
          />
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11" class="my-auto">
          <i>
            {{
              "If the new hourly rate date field is unspecified, the new hourly rate will be directly applied to the current hourly rate"
            }}
          </i>
        </a-col>
        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('future_hourly_rate_date') ? 'error' : ''"
            :help="$validator.errors.first('future_hourly_rate_date')"
            label="New hourly rate date"
          >
            <a-date-picker
              :value="newRoleExtension.future_hourly_rate_date"
              :disabled-date="isDisabledDate"
              class="w-100"
              data-vv-name="future_hourly_rate_date"
              data-vv-as="future hourly rate date"
              @change="(d) => (newRoleExtension.future_hourly_rate_date = d?.format('YYYY-MM-DD'))"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item label="Current Workload">
            <strong v-if="role">{{ role.workload * 100 }}%</strong>
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <SkInput
            v-model="newWorkloadInput"
            v-validate="'required|integer|min_value:0|max_value:100'"
            label="New workload"
            :label-col="{ span: 25 }"
            :wrapper-col="{ span: 24 }"
            :error="$validator.errors.first('new_workload')"
            data-vv-name="new_workload"
            data-vv-as="new workload"
            placeholder="Workload in percentages"
            addon-after="%"
          />
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item label="Current notice period">
            <strong v-if="role">{{ role.notice_period }} days</strong>
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <SkInput
            v-model="newRoleExtension.notice_period"
            v-validate="'required|integer|min_value:0'"
            label="New notice period"
            :label-col="{ span: 25 }"
            :wrapper-col="{ span: 24 }"
            :error="$validator.errors.first('notice_period')"
            data-vv-name="notice_period"
            data-vv-as="notice period"
            placeholder="Notice period in days"
            addon-after="days"
          />
        </a-col>
      </a-row>
    </a-form>

    <SkSelect
      v-model="newRoleExtension.initiated_by"
      v-validate="'required'"
      :error="$validator.errors.first('initiated_by')"
      :options="initiatedByOptions"
      data-vv-name="initiated_by"
      data-vv-as="initiator"
      label="Initiated by"
      placeholder="Initiated by"
      display-prop="formatted_name"
    />

    <SkInput
      v-model="newRoleExtension.pipedrive_id"
      v-validate="'integer|min_value:0'"
      :error="$validator.errors.first('pipedrive_id')"
      data-vv-name="pipedrive_id"
      data-vv-as="PipeDrive ID"
      label="PipeDrive ID"
      placeholder="PipeDrive ID"
    />

    <SkTextarea
      v-model="newRoleExtension.comment"
      data-vv-name="comment"
      placeholder="Comment"
      label="Comment"
    />
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { RoleInterface, RoleExtensionInterface } from "@/modules/roles/types";
import { BaseAclInterface } from "@/modules/authentication/types";
import { INITIATED_BY_OPTIONS } from "@/modules/roles/types";
import SkTextarea from "../../../common/SkTextarea.vue";
import { disableDate } from "../_utils/disabled-dates";
import SkSelect from "../../../common/SkSelect.vue";
import SkInput from "../../../common/SkInput.vue";
import useMixin from "@/useMixin";
import moment from "@/date";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  role: { type: Object as () => RoleInterface, default: undefined },
});

// Emits
const emits = defineEmits(["create-role-extension", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;

// Data properties
const initiatedByOptions = ref(INITIATED_BY_OPTIONS);
const newRoleExtension = ref<RoleExtensionInterface>({
  end_date: "",
  future_hourly_rate_date: "",
  hourly_rate_cents: 0,
  hourly_rate_currency: "",
  workload: 0,
  notice_period: 0,
  initiated_by: "",
  pipedrive_id: 0,
  comment: "",
});
const newHourlyRateInput = ref<string>("");
const newWorkloadInput = ref<string>("");

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newRoleExtension.value = setObject(newRoleExtension.value, undefined);
      newRoleExtension.value.end_date = moment(props.role.end_date).add(1, "days").format();
      newRoleExtension.value.hourly_rate_currency = props.role.hourly_rate
        ? props.role.hourly_rate.currency_code
        : "SEK";
      newRoleExtension.value.notice_period = props.role.notice_period;
      newHourlyRateInput.value = ((props.role.hourly_rate?.value ?? 0) / 100).toString();
      newWorkloadInput.value = (props.role.workload * 100).toString();
    }
  }
);

// Component methods
const handleCreateRoleExtension = (): void => {
  $validator?.errors.clear();
  $validator?.validate().then((result: any) => {
    if (result) {
      fillNewRoleExtensionWithInputs();
      emits("create-role-extension", props.role.id, newRoleExtension.value);
      emits("close");
    }
  });
};

const fillNewRoleExtensionWithInputs = (): void => {
  newRoleExtension.value.hourly_rate_cents = parseFloat(newHourlyRateInput.value) * 100;
  newRoleExtension.value.workload = parseFloat(newWorkloadInput.value) / 100;
};

const isDisabledDate = (date: moment.Moment): boolean => {
  return disableDate(date, props.role);
};
</script>
