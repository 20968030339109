import { defineStore, acceptHMRUpdate } from "pinia";
import {
  JobPostingDetailsInterface,
  JobPostingInterface,
  JobPostingStatus,
} from "@/modules/job_postings/types";
import {
  apiGetJobPostingDetails,
  apiGetJobPostings,
  apiPostJobPostingInterest,
} from "@/modules/job_postings/_utils/api";
import { Router } from "vue-router";

export interface StateInterface {
  newJobPostings: Array<JobPostingInterface>;
  newJobPostingsPagination: JobPostingsPaginationInterface;
  loadingNewJobPostings: boolean;

  ongoingJobPostings: Array<JobPostingInterface>;
  ongoingJobPostingsPagination: JobPostingsPaginationInterface;
  loadingOngoingJobPostings: boolean;

  loadingSelectedJobPosting: boolean;
  selectedJobPosting: JobPostingInterface | undefined;
  selectedJobPostingDetails: JobPostingDetailsInterface | undefined;
}

export interface JobPostingsPaginationInterface {
  start: number;
  limit: number;
  has_more_data?: boolean;
  next_start?: number;
}

export const useJobPostingsStore = defineStore("job_postings", {
  state: (): StateInterface => {
    return {
      newJobPostings: [],
      newJobPostingsPagination: { start: 0, limit: 10 },
      loadingNewJobPostings: false,

      ongoingJobPostings: [],
      ongoingJobPostingsPagination: { start: 0, limit: 10 },
      loadingOngoingJobPostings: false,

      loadingSelectedJobPosting: false,
      selectedJobPosting: undefined,
      selectedJobPostingDetails: undefined,
    };
  },
  actions: {
    getNewJobPostings(withIncrement = false) {
      if (withIncrement) {
        this.incrementNewJobPostingsPagination();
      }

      return new Promise((resolve, reject) => {
        this.setLoadingNewJobPostings(true);

        apiGetJobPostings(
          this.newJobPostingsPagination.start,
          this.newJobPostingsPagination.limit,
          JobPostingStatus.NEW
        )
          .then((res: any) => {
            this.setNewJobPostings([
              ...(withIncrement ? this.newJobPostings : []),
              ...res.data.data,
            ]);
            this.setNewJobPostingsPagination(res.data.meta);
            resolve(res.data);
          })
          .catch((err: any) => {
            reject(err);
          })
          .finally(() => this.setLoadingNewJobPostings(false));
      });
    },

    getOngoingJobPostings(withIncrement = false) {
      if (withIncrement) {
        this.incrementOngoingJobPostingsPagination();
      }

      return new Promise((resolve, reject) => {
        this.setLoadingOngoingJobPostings(true);

        apiGetJobPostings(
          this.ongoingJobPostingsPagination.start,
          this.ongoingJobPostingsPagination.limit,
          JobPostingStatus.ONGOING
        )
          .then((res: any) => {
            this.setOngoingJobPostings([
              ...(withIncrement ? this.ongoingJobPostings : []),
              ...res.data.data,
            ]);
            this.setOngoingJobPostingsPagination(res.data.meta);
            resolve(res.data);
          })
          .catch((err: any) => {
            reject(err);
          })
          .finally(() => this.setLoadingOngoingJobPostings(false));
      });
    },

    showSelectedJobPostingDetails(jobPosting: JobPostingInterface, router: Router): any {
      if (this.selectedJobPosting?.id === jobPosting.id) {
        return;
      }

      router.replace({ query: { id: jobPosting.id } });
      this.setSelectedJobPosting(jobPosting);
      this.clearSelectedJobPostingDetails();
      this.setLoadingSelectedJobPostingDetails(true);

      return new Promise((resolve, reject) => {
        apiGetJobPostingDetails(jobPosting.id)
          .then((res: any) => {
            this.setSelectedJobPostingDetails(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            this.clearSelectedJobPosting();
            reject(err);
          })
          .finally(() => this.setLoadingSelectedJobPostingDetails(false));
      });
    },

    hideSelectedJobPostingDetails() {
      this.clearSelectedJobPosting();
      this.clearSelectedJobPostingDetails();
    },

    sendJobPostingInterest(): any {
      if (!this.selectedJobPosting || this.selectedJobPostingDetails?.has_shown_interest) {
        return;
      }

      const selectedJobPostingId = this.selectedJobPosting.id;

      return new Promise((resolve, reject) => {
        apiPostJobPostingInterest(selectedJobPostingId)
          .then(() => {
            this.setSelectedJobPostingShowInterest();
            resolve(true);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    // NEW job postings
    setNewJobPostings(newJobPostings: Array<JobPostingInterface>): void {
      this.newJobPostings = newJobPostings;
    },

    setNewJobPostingsPagination(newJobPostingsPagination: JobPostingsPaginationInterface): void {
      this.newJobPostingsPagination = newJobPostingsPagination;
    },

    setLoadingNewJobPostings(loadingNewJobPostings: boolean): void {
      this.loadingNewJobPostings = loadingNewJobPostings;
    },

    incrementNewJobPostingsPagination(): void {
      this.newJobPostingsPagination.start = this.newJobPostingsPagination.next_start ?? 0;
    },

    // ONGOING job postings
    setOngoingJobPostings(ongoingJobPostings: Array<JobPostingInterface>): void {
      this.ongoingJobPostings = ongoingJobPostings;
    },

    setOngoingJobPostingsPagination(
      ongoingJobPostingsPagination: JobPostingsPaginationInterface
    ): void {
      this.ongoingJobPostingsPagination = ongoingJobPostingsPagination;
    },

    setLoadingOngoingJobPostings(loadingOngoingJobPostings: boolean): void {
      this.loadingOngoingJobPostings = loadingOngoingJobPostings;
    },

    incrementOngoingJobPostingsPagination(): void {
      this.ongoingJobPostingsPagination.start = this.ongoingJobPostingsPagination.next_start ?? 0;
    },

    // Selected job posting
    setLoadingSelectedJobPostingDetails(loadingSelectedJobPosting: boolean): void {
      this.loadingSelectedJobPosting = loadingSelectedJobPosting;
    },

    setSelectedJobPosting(selectedJobPosting: JobPostingInterface): void {
      this.selectedJobPosting = selectedJobPosting;
    },

    clearSelectedJobPosting(): void {
      this.selectedJobPosting = undefined;
    },

    setSelectedJobPostingDetails(selectedJobPostingDetails: JobPostingDetailsInterface): void {
      this.selectedJobPostingDetails = selectedJobPostingDetails;
    },

    clearSelectedJobPostingDetails(): void {
      this.selectedJobPostingDetails = undefined;
    },

    setSelectedJobPostingShowInterest(): void {
      if (this.selectedJobPostingDetails) {
        this.selectedJobPostingDetails.has_shown_interest = true;
      }
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJobPostingsStore, import.meta.hot));
}
