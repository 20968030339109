<template>
  <div>
    <div v-if="latestRoleDescriptionFormExists">
      <strong>Latest role description form answers:</strong>
      <br />
      <a-card style="margin-top: 10px">
        <a-row>
          <strong>Client Business Sector:</strong>
          {{ roleDescriptionForm.q4 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">Client's need:</strong>
          {{ roleDescriptionForm.q5 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">How did you address their needs?</strong>
          {{ roleDescriptionForm.q6 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">How did your work answer their needs?</strong>
          {{ roleDescriptionForm.q7 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">
            How will the work you did affect the future of the client or this particular case?
          </strong>
          {{ roleDescriptionForm.q8 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">Will there be lasting change as a result of your work?</strong>
          {{ roleDescriptionForm.q9 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">How happy was the client with your involvement?</strong>
          {{ roleDescriptionForm.q10 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">How well did you work with the team?</strong>
          {{ roleDescriptionForm.q11 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">
            Would you like to do something similar again, which part was the most interesting?
          </strong>
          {{ roleDescriptionForm.q12 }}
        </a-row>
      </a-card>
    </div>
    <div v-else><strong>Latest role description form answers:</strong> <em>None</em></div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, toRef, onMounted } from "vue";
import { RoleInterface, RoleDescriptionFormInterface } from "@/modules/roles/types";
import { apiGetRoleDescriptionFormAnswers } from "@/modules/projects/_utils/api";

//Props
const props = defineProps({
  role: { type: Object as () => RoleInterface, default: undefined },
  sent: { type: Boolean, default: false },
});

//Data properties
const latestRoleDescriptionFormExists = ref<Boolean>(false);
const roleDescriptionForm = ref<RoleDescriptionFormInterface>({
  q4: undefined,
  q5: undefined,
  q6: undefined,
  q7: undefined,
  q8: undefined,
  q9: undefined,
  q10: undefined,
  q11: undefined,
  q12: undefined,
});

// Watchers
watch(toRef(props, "sent"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    fetchRoleDescriptionFormAnswers();
  }
});

// Lifecycle hooks
onMounted(() => {
  fetchRoleDescriptionFormAnswers();
});

// Class methods
const fetchRoleDescriptionFormAnswers = () => {
  roleDescriptionForm.value.role_id = props.role.id;
  apiGetRoleDescriptionFormAnswers(roleDescriptionForm.value.role_id)
    .then((res: any) => {
      roleDescriptionForm.value = res.data.role_description_forms[0]; //displaying only the most recent answers for now
      latestRoleDescriptionFormExists.value = true;
    })
    .catch((error: any) => {
      if (error) {
        latestRoleDescriptionFormExists.value = false;
      }
    });
};
</script>
