<template>
  <div class="vacation-overview card">
    <div class="upper-section">
      <div class="vacation-labels">
        <span class="heading-text">Maximum vacation this year:</span>
        <span class="upper-span">
          {{ maximumVacationDays }}
          <span v-if="daysSaved > 0 || daysPrepaid > 0">
            (
            <span v-if="daysSaved > 0">{{ daysSaved }} saved</span>
            <span v-if="daysSaved > 0 && daysPrepaid > 0">, </span>
            <span v-if="daysPrepaid > 0">{{ daysPrepaid }} prepaid</span>
            )
          </span>
        </span>
        <span class="heading-text">Days left to use this year:</span>

        <span class="lower-span">
          {{ daysLeft }}
          <span v-if="daysSaved > 0 || daysPrepaid > 0">
            (
            <span v-if="daysSaved > 0 && daysLeft > 0">
              {{ daysLeft > daysSaved ? daysSaved : daysLeft }} saved
              <span v-if="daysPrepaid > 0 && daysLeft > 0">, </span>
            </span>
            <span v-if="daysPrepaid > 0 && daysLeft > 0">
              {{ daysLeft > daysPrepaid ? daysPrepaid : daysLeft }} prepaid
            </span>
            )
          </span>
        </span>
      </div>

      <div class="vacation-table">
        <div>
          <span class="heading-text">Reported vacation days this year:</span>
          <span>{{ reportedDays }}</span>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Amount of Days</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="report in vacationReports" :key="report.id">
              <td>{{ report.start_date }}</td>
              <td>{{ report.end_date }}</td>
              <td>{{ report.total_days }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- New table for vacation days history -->
    <div class="vacation-table-history">
      <table>
        <thead>
          <tr>
            <th>Year</th>
            <th>Earned vacation days</th>
            <th>Paid vacation days</th>
            <th>Reported</th>
            <th>Vacation days saved</th>
            <th>Saved days used</th>
            <th>Paid out</th>
            <th>Prepaid</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="vacationInfo in historicalVacationYears" :key="vacationInfo.year">
            <td>{{ vacationInfo.year }}</td>
            <td>{{ vacationInfo.vacation_days_earned }}</td>
            <td>{{ vacationInfo.vacation_days_paid }}</td>
            <td>{{ vacationInfo.vacation_days_reported || 0 }}</td>
            <td>{{ vacationInfo.vacation_days_saved }}</td>
            <td>{{ vacationInfo.vacation_days_saved_used || 0 }}</td>
            <td>{{ calculatePaidOut(vacationInfo) }}</td>
            <td>{{ vacationInfo.vacation_days_prepaid }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, watch, set, computed } from "vue";
import { apiGetReports, apiGetAllVacationYears } from "@/modules/users/_utils/api";
import { ProfileInterface } from "../../authentication/types";
import { computeNbBusinessDaysV2 } from "@/modules/reports/_utils/public-holidays";
import moment from "@/date";
import { useReportsStore } from "@/modules/reports/_store";
import { ReportInterface, PublicHolidayInterface, ReportType } from "@/modules/reports/types";

interface VacationInformation {
  id: number;
  saved_days_balance: number;
  vacation_days_earned: number;
  vacation_days_paid: number;
  vacation_days_prepaid: number;
  vacation_days_saved: number;
  year: number;
  vacation_days_reported?: number;
  vacation_days_saved_used?: number;
}

// Props
const props = defineProps({
  profile: { type: Object as () => ProfileInterface, default: undefined },
});

// Data properties
const daysLeft = ref<number>(0);
const daysSaved = ref<number>(0);
const reportedDays = ref<number>(0);
const daysPrepaid = ref<number>(0);
const vacationYears = ref<Array<VacationInformation>>([]);
let vacationReports = ref<Array<ReportInterface>>([]);
const publicHolidays = ref<Array<PublicHolidayInterface>>([]);
const reportsStore = useReportsStore();
let totalReportedDays = ref<number>(0);
const maximumVacationDays = ref<number>(0);
const currentYear = moment().year();

const calculatePaidOut = (vacationInfo: VacationInformation) => {
  const {
    vacation_days_paid = 0,
    vacation_days_reported = 0,
    vacation_days_saved = 0,
  } = vacationInfo;

  return Math.max(vacation_days_paid - vacation_days_reported - vacation_days_saved, 0);
};

watch([publicHolidays, vacationReports, maximumVacationDays], () => {
  totalReportedDays.value = 0;
  if (vacationReports.value.length > 0 && publicHolidays.value.length > 0) {
    vacationReports.value.forEach((report, index) => {
      const total_days = Math.floor(
        computeNbBusinessDaysV2(report.start_date, report.end_date, publicHolidays.value)
      );

      totalReportedDays.value += total_days;
      set(vacationReports.value, index, {
        ...report,
        total_days: total_days,
      });
    });
  }
  daysLeft.value = maximumVacationDays.value - totalReportedDays.value;
});

// Lifecycle hooks
onBeforeMount(async () => {
  // Fetch all vacation years for the user
  apiGetAllVacationYears(props.profile.id).then((res: any) => {
    vacationYears.value = res.data.data;

    // Find the current year's data for specific computations
    const currentYearData = vacationYears.value.find((year) => year.year === currentYear);
    if (currentYearData) {
      const {
        vacation_days_paid,
        vacation_days_earned,
        saved_days_balance,
        vacation_days_reported,
      } = currentYearData;
      const earnedOrPaid = Math.max(vacation_days_paid, vacation_days_earned);
      maximumVacationDays.value = earnedOrPaid + saved_days_balance;
      daysSaved.value = saved_days_balance;
      daysPrepaid.value = vacation_days_earned - vacation_days_paid;
      reportedDays.value = totalReportedDays;
    }
  });

  apiGetReports({
    user_id: [props.profile.id],
    types: [ReportType.VACATION],
    start_date: firstDayOfTheCurrentYear(),
  }).then((res: any) => {
    vacationReports.value = res.data.data;
  });

  publicHolidays.value = await fetchPublicHolidaysForCurrentYear();
});

const historicalVacationYears = computed(() => {
  return vacationYears.value.filter((year) => year.year !== currentYear);
});

const firstDayOfTheCurrentYear = (): Date => {
  return moment([currentYear]).format("YYYY.M.D");
};

const fetchPublicHolidaysForCurrentYear = async () => {
  const allPublicHolidays = [];

  const yearFormatted = moment({ year: currentYear }).format("YYYY");
  const response = await reportsStore.getPublicAnnualHolidays(yearFormatted);
  allPublicHolidays.push(...response.data);

  return allPublicHolidays;
};
</script>

<style scoped>
.vacation-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.upper-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.vacation-labels {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upper-span,
.lower-span {
  display: block;
}

.upper-span {
  margin-bottom: 1.5rem;
}

.heading-text {
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
  color: #333;
}

.vacation-table,
.vacation-table-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.table,
.vacation-table-history {
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
}

.table th,
.table td,
.vacation-table-history th,
.vacation-table-history td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
}

.table th,
.vacation-table-history th {
  background-color: #f0f0f0;
  color: #333;
}
</style>
