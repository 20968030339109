<template>
  <div v-show="visible">
    <h3>Time Report</h3>

    <a-spin size="large" :spinning="checking" class="d-flex justify-content-center" />
    <div v-show="checking">
      <p class="d-flex justify-content-center mt-4">
        Checking to see if a time report already exists…
      </p>
    </div>

    <div v-show="timeReportExists !== null && timeReportExists && !checking">
      <a-icon type="check-circle" class="mr-1" />
      You've already created a time report
    </div>

    <div v-show="timeReportExists !== null && !timeReportExists && !checking">
      <p>All that's left for you to do is to create a time report.</p>
      <p>
        Close this popup, go to your Dashboard and fill in the relevant information under
        <b>Time Report</b>.
      </p>
      <p>
        Select {project role} as the project role and input some integer number of hours. The rest
        of the information is already filled in.
        <br />
        Click submit.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { ProfileInterface } from "@/modules/authentication/types";
import { apiGetReports } from "@/modules/reports/_utils/api";
import { ReportType } from "@/modules/reports/types";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  user: { type: Object as () => ProfileInterface, default: () => ({}) },
  shouldReload: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update"]);

// Data properties
const checking = ref<boolean>(false);
const timeReportExists = ref<boolean | null>(null);

// Lifecycle hooks
onMounted(() => {
  checkIfTimeReportExists(false);
});

// Watchers
watch(
  () => props.visible,
  (newProp) => {
    if (newProp) {
      checkIfTimeReportExists();
    }
  },
  { immediate: true }
);

// Class methods
const checkIfTimeReportExists = async (wait: boolean = true) => {
  checking.value = true;

  if (wait) {
    // wait 1 second, for better UX
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  apiGetReports({
    user_id: [props.user.id],
    types: [ReportType.TIME],
  })
    .then((res: any) => {
      timeReportExists.value = res.data.data.length > 0;
      emits("update", timeReportExists.value);
    })
    .catch(() => {
      timeReportExists.value = false;
    })
    .finally(() => {
      checking.value = false;
    });
};
</script>
