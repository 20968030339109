<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :width="900"
    title="Answer a new work experience description form"
    ok-text="Send"
    @ok="handleSendWorkExperienceDescriptionForm"
    @cancel="$emit('close')"
  >
    <a-form data-vv-scope="work-experience-description-form-modal" layout="horizontal">
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
        <strong> Skills </strong>
        <br />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="Are the skills of the work experience up to date?"
      >
        <a-select
          v-model="newWorkExperience.skill_ids"
          mode="multiple"
          show-search
          placeholder="Select skills"
          option-filter-prop="children"
        >
          <a-select-option v-for="s in skills" :key="s.id" :class="{ 'already-in': !!s.skill_id }">
            {{ s.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <hr style="padding=20px" />
      <br />
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
        <strong> Work experience description form </strong>
        <br />
      </a-form-item>
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="1. Client name">
        <p>{{ user.name }}</p>
      </a-form-item>
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="2. Company name">
        <p>{{ newWorkExperience.company_name }}</p>
      </a-form-item>
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="3. Title">
        <p>{{ newWorkExperience.title }}</p>
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="4. Client Business Sector"
      >
        <a-textarea v-model="newWorkExperienceDescriptionForm.q4" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="5. Client's need">
        <a-textarea v-model="newWorkExperienceDescriptionForm.q5" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="6. How did you address their needs?"
      >
        <a-textarea v-model="newWorkExperienceDescriptionForm.q6" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="7. How did your work answer their needs?"
      >
        <a-textarea v-model="newWorkExperienceDescriptionForm.q7" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="8. How will the work you did affect the future of the client or this particular case?"
      >
        <a-textarea v-model="newWorkExperienceDescriptionForm.q8" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="9. Will there be lasting change as a result of your work?"
      >
        <a-textarea v-model="newWorkExperienceDescriptionForm.q9" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="10. How happy was the client with your involvement?"
      >
        <a-textarea v-model="newWorkExperienceDescriptionForm.q10" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="11. How well did you work with the team?"
      >
        <a-textarea v-model="newWorkExperienceDescriptionForm.q11" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="12. Would you like to do something similar again? Which part was the most interesting?"
      >
        <a-textarea v-model="newWorkExperienceDescriptionForm.q12" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="13. Current description"
      >
        <p>{{ newWorkExperience.description }}</p>
      </a-form-item>
    </a-form>
    <!-- Spinner -->
    <div v-if="spin" style="padding: 20px">
      <a-spin tip="Sending...">
        <div class="spin-content"></div>
      </a-spin>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { apiSendWorkExperienceDescriptionForm } from "@/modules/users/_utils/api";
import { ProfileInterface } from "@/modules/authentication/types";
import { SkillInterface } from "@/modules/skills/types";
import useMixin from "@/useMixin";
import {
  WorkExperienceInterface,
  WorkExperienceDescriptionFormInterface,
} from "@/modules/users/types";

// Props
const props = defineProps({
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  visible: { type: Boolean, default: false },
  job: { type: Object, default: undefined },
});

// Emits
const emits = defineEmits([
  "set-work-experience-description-form-sent",
  "update-work-experience",
  "get-skills",
  "close",
]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;
const $validator = instance?.proxy.$validator;

// Data properties
const spin = ref(false);
const sent = ref(false);
const newWorkExperience = ref<WorkExperienceInterface>({
  id: 0,
  company_name: "",
  title: "",
  start_date: "",
  end_date: "",
  description: undefined,
  skill_ids: undefined,
});
const newWorkExperienceDescriptionForm = ref<WorkExperienceDescriptionFormInterface>({
  exists: false,
  work_experience_id: undefined,
  q4: "",
  q5: "",
  q6: "",
  q7: "",
  q8: "",
  q9: "",
  q10: "",
  q11: "",
  q12: "",
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      emits("get-skills");

      newWorkExperience.value = setObject(newWorkExperience.value, props.job);
      newWorkExperience.value.id = props.job.id;
      newWorkExperience.value.company_name = props.job.company_name;
      newWorkExperience.value.title = props.job.title;
      newWorkExperience.value.description = props.job.description ?? "< empty >";
      newWorkExperience.value.skill_ids = props.job.skill_ids;

      newWorkExperienceDescriptionForm.value = setObject(
        newWorkExperienceDescriptionForm.value,
        undefined
      );
      newWorkExperienceDescriptionForm.value.exists = true;
      newWorkExperienceDescriptionForm.value.work_experience_id = props.job.id;
    }
  }
);

watch(sent, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    emits("close");
    newWorkExperienceDescriptionForm.value = setObject(
      newWorkExperienceDescriptionForm.value,
      undefined
    );
    newWorkExperience.value = setObject(newWorkExperience.value, undefined);
  }
});

// Class methods
const handleSendWorkExperienceDescriptionForm = (): void => {
  $validator?.validate().then((result) => {
    if (result) {
      sendWorkExperienceDescriptionForm(newWorkExperienceDescriptionForm.value);
      emits("update-work-experience", newWorkExperience.value);
    }
  });
};

const sendWorkExperienceDescriptionForm = (
  newWorkExperienceDescriptionForm: WorkExperienceDescriptionFormInterface
): void => {
  spin.value = true;
  sent.value = false;
  emits("set-work-experience-description-form-sent", sent.value);
  apiSendWorkExperienceDescriptionForm(props.user, newWorkExperienceDescriptionForm)
    .then(() => {
      spin.value = false;
      $message?.success("Work experience description form sent successfully!", 5);
      sent.value = true;
      emits("set-work-experience-description-form-sent", sent.value);
    })
    .catch(() => {
      spin.value = false;
      $message?.error("Couldn't send work experience description form. Try again.", 5);
    });
};
</script>
