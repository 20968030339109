<template>
  <div v-show="visible">
    <h3>Career</h3>

    <a-row v-if="showMissingFieldErrors && missingProfileDescriptionForm" class="text-red">
      <p>Missing profile description. Please fill out the description form.</p>
    </a-row>

    <user-description
      :user="user"
      :acl="acl"
      :edit-enabled="false"
      @update-profile="handleUpdateProfile"
      @profile-description-form-sent="handleProfileDescriptionFormSent"
    />

    <div class="mb-4" />

    <a-row v-if="showMissingFieldErrors && missingAtLeastOneEducation" class="text-red">
      <p>You must add at least one education.</p>
    </a-row>

    <user-educations
      :educations="user.educations"
      :skills="skills"
      :acl="acl"
      @store-education="(a) => handleStoreExperience({ a, type: 'educations' })"
      @update-education="(a) => handleUpdateExperience({ a, type: 'educations' })"
      @destroy-education="(id) => handleDestroyExperience({ id, type: 'educations' })"
      @get-skills="handleGetSkills()"
    />

    <div class="mb-4" />

    <a-row
      v-if="showMissingFieldErrors && workExperiencesWithMissingForms.length > 0"
      class="text-red"
    >
      <p v-if="workExperiencesWithMissingForms.length === 1">
        You must fill in the description form for the work experience
        <b>{{ workExperiencesWithMissingForms[0] }}</b
        >.
      </p>
      <p v-else>
        You must fill in the description forms for the work experiences
        <b>{{ workExperiencesWithMissingForms.join(", ") }}</b
        >.
      </p>
    </a-row>

    <user-jobs
      :jobs="user.work_experiences"
      :skills="skills"
      :acl="acl"
      :user="user"
      @store-job="(a) => handleStoreExperience({ a, type: 'work_experiences' })"
      @update-job="(a) => handleUpdateExperience({ a, type: 'work_experiences' })"
      @destroy-job="(id) => handleDestroyExperience({ id, type: 'work_experiences' })"
      @get-skills="handleGetSkills()"
      @work-experience-description-form-sent="
        (title) => handleWorkExperienceDescriptionFormSent(title)
      "
    />

    <div class="mb-4" />

    <user-merits
      :merits="user.merits"
      :acl="acl"
      @store-merit="(a) => handleStoreExperience({ a, type: 'merits' })"
      @update-merit="(a) => handleUpdateExperience({ a, type: 'merits' })"
      @destroy-merit="(id) => handleDestroyExperience({ id, type: 'merits' })"
    />

    <div class="mb-4" />

    <a-row v-if="showMissingFieldErrors && missingAtLeastOneSkill" class="text-red">
      <p>You must add at least one skill.</p>
    </a-row>

    <skills-card
      :user="user"
      :acl="acl"
      :skills="skills"
      :user-skills="userSkills"
      @store-user-skill="handleStoreUserSkill"
      @update-user-skill="handleUpdateUserSkill"
      @destroy-user-skill="handleDestroyUserSkill"
      @get-skills="handleGetSkills"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, ComputedRef, computed, getCurrentInstance } from "vue";
import { apiGetExperiences, apiGetExperienceDescriptionFormAnswers } from "../_utils/api";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import UserDescription from "../../users/_components/UserDescription.vue";
import UserEducations from "../../users/_components/UserEducations.vue";
import SkillsCard from "../../users/_components/SkillsCard.vue";
import UserMerits from "../../users/_components/UserMerits.vue";
import UserJobs from "../../users/_components/UserJobs.vue";
import { SkillInterface } from "@/modules/skills/types";
import {
  apiDestroyUserSkill,
  apiGetSkills,
  apiGetUserSkills,
  apiStoreUserSkill,
  apiUpdateUserSkill,
} from "@/modules/skills/_utils/api";
import {
  apiDestroyExperience,
  apiGetProfileDescriptionFormAnswers,
  apiStoreExperience,
  apiUpdateExperience,
  apiUpdateUser,
} from "@/modules/users/_utils/api";

// Props
const props = defineProps({
  baseAcl: { type: Object as () => BaseAclInterface, default: undefined },
  user: { type: Object as () => ProfileInterface, default: undefined },
  showMissingFieldErrors: { type: Boolean, default: false },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["reload-user", "update"]);

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const userSkills = ref<Array<SkillInterface>>([]);
const skills = ref<Array<SkillInterface>>([]);
const missingProfileDescriptionForm = ref<boolean>(false);
const missingAtLeastOneEducation = ref<boolean>(false);
const workExperiencesWithMissingForms = ref<Array<string>>([]);
const workExperienceFormsSentDuringSession = ref<Array<String>>([]);
const missingAtLeastOneSkill = ref<boolean>(false);

// Lifecycle hooks
onMounted(() => {
  emits("reload-user");
  apiGetUserSkills(props.user.id).then((res: any) => (userSkills.value = res.data.data));
});

// Watchers
watch([() => props.visible, () => props.user, () => userSkills.value], async () => {
  updateStepCompleteness();
});

// Class methods
const acl: ComputedRef<BaseAclInterface & { isOwner: boolean }> = computed(() => {
  return { ...props.baseAcl, isOwner: true };
});

const handleUpdateProfile = (updatedUser: ProfileInterface): void => {
  if (props.user === null) return;
  apiUpdateUser(props.user.id, updatedUser)
    .then(() => emits("reload-user"))
    .then(() => $message?.success("Profile successfully updated!", 3))
    .catch(() => $message?.error("Couldn't update the profile!", 3));
};

const handleProfileDescriptionFormSent = (): void => {
  updateStepCompleteness();
};

const handleStoreExperience = ({ a, type }: { a: any; type: any }): void => {
  if (props.user === null) return;
  apiStoreExperience(type, props.user.id, a)
    .then(() => emits("reload-user"))
    .then(() => $message?.success("Experience successfully created!", 3))
    .catch(() => $message?.error("Couldn't create experience!", 3));
};

const handleUpdateExperience = ({ a, type }: { a: any; type: any }): void => {
  if (props.user === null) return;
  apiUpdateExperience(type, props.user.id, a.id, a)
    .then(() => emits("reload-user"))
    .then(() => $message?.success("Experience successfully updated!", 3))
    .catch(() => $message?.error("Couldn't update experience!", 3));
};

const handleDestroyExperience = ({ id, type }: { id: number; type: any }): void => {
  if (props.user === null) return;
  apiDestroyExperience(type, props.user.id, id)
    .then(() => emits("reload-user"))
    .then(() => $message?.success("Experience successfully removed!", 3))
    .catch(() => $message?.error("Couldn't remove experience!", 3));
};

const handleWorkExperienceDescriptionFormSent = (title: string): void => {
  workExperienceFormsSentDuringSession.value.push(title);
};

const handleGetSkills = (): void => {
  apiGetSkills().then((res: any) => (skills.value = res.data.data));
};

const handleStoreUserSkill = (newUserSkill: SkillInterface): void => {
  if (props.user === null) return;
  apiStoreUserSkill(props.user.id, newUserSkill)
    .then(() => {
      if (props.user != null) {
        apiGetUserSkills(props.user.id).then((res: any) => (userSkills.value = res.data.data));
      }
    })
    .then(() => $message?.success("User skill successfully added!", 3))
    .catch(() => $message?.error("Couldn't add user skill!", 3));
};

const handleUpdateUserSkill = (updatedUserSkill: SkillInterface): void => {
  if (props.user === null) return;
  apiUpdateUserSkill(props.user.id, updatedUserSkill.id, updatedUserSkill)
    .then(() => {
      if (props.user != null) {
        apiGetUserSkills(props.user.id).then((res: any) => (userSkills.value = res.data.data));
      }
    })
    .then(() => $message?.success("User skill successfully updated!", 3))
    .catch(() => $message?.error("Couldn't update user skill!", 3));
};

const handleDestroyUserSkill = (userSkill: SkillInterface): void => {
  if (props.user === null) return;
  apiDestroyUserSkill(props.user.id, userSkill.id)
    .then(() => {
      if (props.user != null) {
        apiGetUserSkills(props.user.id).then((res: any) => (userSkills.value = res.data.data));
      }
    })
    .then(() => $message?.success("User skill successfully removed!", 3))
    .catch(() => $message?.error("Couldn't remove user skill!", 3));
};

const updateStepCompleteness = (): void => {
  checkStepCompleteness()
    .then((isComplete: any) => emits("update", isComplete))
    .catch(() => emits("update", false));
};

const checkStepCompleteness = async (): Promise<boolean> => {
  const basicCheck = await checkBasic();
  const profileDescriptionFormCheck = await checkProfileDescriptionForm();
  const workExperienceDescriptionFormsCheck = await checkWorkExperienceDescriptionForms();

  const isComplete =
    basicCheck && profileDescriptionFormCheck && workExperienceDescriptionFormsCheck;
  return new Promise((resolve, _) => resolve(isComplete));
};

const checkBasic = async (): Promise<boolean> => {
  const basicCheck = props.user.educations.length > 0 && userSkills.value.length > 0;

  missingAtLeastOneEducation.value = props.user.educations.length === 0;
  missingAtLeastOneSkill.value = userSkills.value.length === 0;

  return new Promise<boolean>((resolve, _) => {
    resolve(basicCheck);
  });
};

const checkProfileDescriptionForm = async (): Promise<boolean> => {
  let profileDescriptionFormsExists = true;
  try {
    await apiGetProfileDescriptionFormAnswers(props.user.id);
  } catch (err: any) {
    profileDescriptionFormsExists = false;
  }

  missingProfileDescriptionForm.value = !profileDescriptionFormsExists;

  return new Promise<boolean>((resolve, _) => {
    resolve(profileDescriptionFormsExists);
  });
};

const checkWorkExperienceDescriptionForms = async (): Promise<boolean> => {
  const allWorkExperiences = (await apiGetExperiences(props.user.id, "work_experiences")).data.data;

  let workExperiencesDescriptionFormsCheck = true;
  workExperiencesWithMissingForms.value = [];
  for (let workExperience of allWorkExperiences) {
    if (workExperienceFormsSentDuringSession.value.includes(workExperience.title)) {
      continue;
    }
    if (!workExperience.description) {
      try {
        await apiGetExperienceDescriptionFormAnswers(
          props.user.id,
          workExperience.id,
          "work_experiences"
        );
      } catch (err: any) {
        workExperiencesDescriptionFormsCheck = false;
        workExperiencesWithMissingForms.value.push(workExperience.title);
      }
    }
  }

  return new Promise<boolean>((resolve, _) => {
    resolve(workExperiencesDescriptionFormsCheck);
  });
};
</script>
