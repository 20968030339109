<template>
  <div>
    <br />
    <div v-if="latestProfileDescriptionFormExists">
      <a-card style="margin-top: 10px">
        <a-row>
          <strong>Age</strong>
          {{ profileDescriptionForm.q2 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">Education</strong>
          {{ profileDescriptionForm.q3 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">When you got onboard Skry?</strong>
          {{ profileDescriptionForm.q4 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">
            What are you passionate about? What are some of the values that define you?
          </strong>
          {{ profileDescriptionForm.q5 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">Hobbies</strong>
          {{ profileDescriptionForm.q6 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">Volunteering</strong>
          {{ profileDescriptionForm.q7 }}
        </a-row>
        <a-row class="mt-2">
          <strong class="mr-2">
            Other interesting things that you think has defined you as a person?
          </strong>
          {{ profileDescriptionForm.q8 }}
        </a-row>
      </a-card>
    </div>
    <div v-else><strong>Latest profile description form answers:</strong> <em>None</em></div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { apiGetProfileDescriptionFormAnswers } from "@/modules/users/_utils/api";
import { ProfileDescriptionFormInterface } from "@/modules/users/types";
import { ProfileInterface } from "@/modules/authentication/types";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  sent: { type: Boolean, default: false },
});

//Data properties
const latestProfileDescriptionFormExists = ref(false);
const profileDescriptionForm = ref<ProfileDescriptionFormInterface>({
  q2: "",
  q3: "",
  q4: "",
  q5: "",
  q6: "",
  q7: "",
  q8: "",
});

// Watchers
watch(
  () => props.sent,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      fetchProfileDescriptionFormAnswers();
    }
  }
);

//Lifecycle hooks
onMounted(() => {
  fetchProfileDescriptionFormAnswers();
});

// Component methods
const fetchProfileDescriptionFormAnswers = () => {
  apiGetProfileDescriptionFormAnswers(props.user.id)
    .then((res: any) => {
      profileDescriptionForm.value = res.data.profile_description_forms[0];
      latestProfileDescriptionFormExists.value = true;
    })
    .catch((error: any) => {
      if (error) {
        latestProfileDescriptionFormExists.value = false;
      }
    });
};
</script>
