<template>
  <div class="card">
    <div class="mb-3 flex-center-between">
      <h2 class="m-0">Work Experiences</h2>
      <span
        v-if="acl.isOwner || acl.isAdmin"
        class="action-default d-flex justify-content-end align-items-center"
        @click="openAddJobModal"
      >
        <a-icon type="plus" class="mr-2" />Add
      </span>
    </div>

    <!-- LIST OF USER EXPERIENCES -->

    <div v-if="jobs.length > 0">
      <a-list :data-source="jobs" :split="false" item-layout="vertical" size="small">
        <template #renderItem="job">
          <a-list-item class="d-flex justify-content-between flex-column flex-lg-row">
            <div class="mb-1" style="width: 90%">
              <div class="d-flex justify-content-between flex-column flex-lg-row">
                <h3 class="m-0">{{ job.title }} at {{ job.company_name }}</h3>
                <span v-if="job.start_date">
                  <em>{{ formatDateForCV(job.start_date) }} - </em>
                  <em v-if="job.end_date">{{ formatDateForCV(job.end_date) }}</em>
                  <em v-else>ongoing</em>
                </span>
              </div>
              <span>{{ job.description }}</span>
              <a :href="`${job.url_link}`" target="_blank" class="d-flex mb-1">
                {{ job.url_link }}
              </a>
            </div>
            <div v-if="acl.isOwner || acl.isAdmin" class="d-flex justify-content-end">
              <a-tooltip>
                <template #title> Fill in a new work experience description form </template>
                <a-icon
                  type="file-text"
                  class="action-default"
                  @click="showAnswerWorkExperienceDescriptionForm(job)"
                />
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title> Edit work experience information </template>
                <a-icon type="edit" class="action-default" @click="openEditJobModal(job)" />
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title> Delete work experience </template>
                <a-icon
                  type="delete"
                  class="action-danger"
                  @click="showDeleteWorkExperienceModal(job)"
                />
              </a-tooltip>
            </div>
          </a-list-item>
        </template>
      </a-list>
    </div>

    <!-- ADD JOB MODAL -->
    <a-modal
      v-model="addJobModalVisible"
      :destroy-on-close="true"
      title="Add Work Experience"
      ok-text="Save"
      @ok="handleStoreJob"
    >
      <a-form layout="horizontal" data-vv-scope="add-job">
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-job.company_name') ? 'error' : ''"
          :help="$validator.errors.first('add-job.company_name')"
          label="Company"
        >
          <a-input
            v-model="newJob.company_name"
            v-validate="'required|max:255'"
            data-vv-name="company_name"
            data-vv-as="company name"
            placeholder="Name of the company"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-job.title') ? 'error' : ''"
          :help="$validator.errors.first('add-job.title')"
          label="Title"
        >
          <a-input
            v-model="newJob.title"
            v-validate="'required|max:255'"
            placeholder="Title of the job"
            data-vv-name="title"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-job.description') ? 'error' : ''"
          :help="$validator.errors.first('add-job.description')"
          label="Description"
        >
          <a-textarea
            v-model="newJob.description"
            v-validate="'required|max:1024'"
            :rows="2"
            placeholder="Description of the job"
            data-vv-name="description"
          />
        </a-form-item>

        <a-form data-vv-scope="tab-skills" layout="horizontal">
          <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Skills">
            <a-select
              v-model="newJob.skill_ids"
              mode="multiple"
              show-search
              placeholder="Select skills"
              option-filter-prop="children"
            >
              <a-select-option
                v-for="s in skills"
                :key="s.id"
                :class="{ 'already-in': !!s.skill_id }"
                >{{ s.title }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-form>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-job.start_date') ? 'error' : ''"
          :help="$validator.errors.first('add-job.start_date')"
          label="Start date"
        >
          <a-date-picker
            v-validate="'required'"
            :value="parseDate(newJob.start_date)"
            :disabled-date="disableStartDate"
            data-vv-name="start_date"
            data-vv-as="start date"
            class="w-100"
            @change="(d) => onDateChange(d, 'start_date')"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-job.end_date') ? 'error' : ''"
          :help="$validator.errors.first('add-job.end_date')"
          label="End date"
        >
          <a-date-picker
            :value="parseDate(newJob.end_date)"
            :disabled-date="disableEndDate"
            data-vv-name="end_date"
            data-vv-as="end date"
            class="w-100"
            @change="(d) => onDateChange(d, 'end_date')"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-job.url_link') ? 'error' : ''"
          :help="$validator.errors.first('add-job.url_link')"
          label="URL link"
        >
          <a-input
            v-model="newJob.url_link"
            v-validate="'max:255'"
            placeholder="URL link"
            data-vv-name="url_link"
            data-vv-as="link"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- EDIT JOB MODAL -->
    <a-modal
      v-model="editJobModalVisible"
      :destroy-on-close="true"
      title="Edit Work Experience"
      ok-text="Save"
      @ok="handleUpdateJob"
    >
      <a-form layout="horizontal" data-vv-scope="edit-job">
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-job.company_name') ? 'error' : ''"
          :help="$validator.errors.first('edit-job.company_name')"
          label="Company"
        >
          <a-input
            v-model="newJob.company_name"
            v-validate="'required|max:255'"
            data-vv-name="company_name"
            data-vv-as="company name"
            placeholder="Name of the company"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-job.title') ? 'error' : ''"
          :help="$validator.errors.first('edit-job.title')"
          label="Title"
        >
          <a-input
            v-model="newJob.title"
            v-validate="'required|max:255'"
            placeholder="Title of the job"
            data-vv-name="title"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-job.description') ? 'error' : ''"
          :help="$validator.errors.first('edit-job.description')"
          label="Description"
        >
          <a-textarea
            v-model="newJob.description"
            v-validate="'required|max:1024'"
            placeholder="Description of the job"
            data-vv-name="description"
          />
        </a-form-item>

        <a-form data-vv-scope="tab-skills" layout="horizontal">
          <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Skills">
            <a-select
              v-model="newJob.skill_ids"
              mode="multiple"
              show-search
              placeholder="Select skills"
              option-filter-prop="children"
            >
              <a-select-option
                v-for="s in skills"
                :key="s.id"
                :class="{ 'already-in': !!s.skill_id }"
              >
                {{ s.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-job.start_date') ? 'error' : ''"
          :help="$validator.errors.first('edit-job.start_date')"
          label="Start date"
        >
          <a-date-picker
            v-validate="'required'"
            :value="parseDate(newJob.start_date)"
            :disabled-date="disableStartDate"
            data-vv-name="start_date"
            data-vv-as="start date"
            class="w-100"
            @change="(d) => onDateChange(d, 'start_date')"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-job.end_date') ? 'error' : ''"
          :help="$validator.errors.first('edit-job.end_date')"
          label="End date"
        >
          <a-date-picker
            :value="parseDate(newJob.end_date)"
            :disabled-date="disableEndDate"
            class="w-100"
            @change="(d) => onDateChange(d, 'end_date')"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-job.url_link') ? 'error' : ''"
          :help="$validator.errors.first('edit-job.url_link')"
          label="URL link"
        >
          <a-input
            v-model="newJob.url_link"
            v-validate="'max:255'"
            placeholder="URL link"
            data-vv-name="url_link"
            data-vv-as="link"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- ANSWER WORK EXPERIENCE DESCRIPTION FORM MODAL -->
    <work-experience-description-form-modal
      :visible="answerWorkExperienceDescriptionFormModalVisible"
      :acl="acl"
      :skills="skills"
      :sent="workExperienceDescriptionFormSent"
      :job="newJob"
      :user="user"
      @close="answerWorkExperienceDescriptionFormModalVisible = false"
      @get-skills="$emit('get-skills')"
      @set-work-experience-description-form-sent="
        (sent) => setWorkExperienceDescriptionFormSent(sent)
      "
      @update-work-experience="(job) => handleUpdateWorkExperience(job)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import WorkExperienceDescriptionFormModal from "@/modules/users/_components/WorkExperienceDescriptionFormModal.vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { showDeleteExperienceModal } from "../_utils/experience";
import { WorkExperienceInterface } from "@/modules/users/types";
import { SkillInterface } from "@/modules/skills/types";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  jobs: { type: Array as () => Array<WorkExperienceInterface>, default: () => [] },
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits([
  "get-skills",
  "store-job",
  "update-job",
  "destroy-job",
  "work-experience-description-form-sent",
]);

// Mixins
const { formatDateForCV, setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const newJob = ref<WorkExperienceInterface>({
  id: 0,
  company_name: "",
  title: "",
  start_date: "",
  end_date: "",
  description: undefined,
  skill_ids: undefined,
  url_link: undefined,
});
const answerWorkExperienceDescriptionFormModalVisible = ref<boolean>(false);
const workExperienceDescriptionFormSent = ref<boolean>(false);
const editJobModalVisible = ref<boolean>(false);
const addJobModalVisible = ref<boolean>(false);

// Watchers
watch(
  [addJobModalVisible, editJobModalVisible],
  ([addNewProp, editNewProp], [addOldProp, editOldProp]) => {
    if ((addNewProp && !addOldProp) || (editNewProp && !editOldProp)) {
      emits("get-skills");
    }
  }
);

//Component methods
const openAddJobModal = (): void => {
  $validator?.errors.clear();
  newJob.value = setObject(newJob.value, undefined);
  addJobModalVisible.value = true;
};

const handleStoreJob = (): void => {
  $validator?.validateAll("add-job").then((result) => {
    if (result) {
      emits("store-job", newJob.value);
      addJobModalVisible.value = false;
    }
  });
};

const openEditJobModal = (job: WorkExperienceInterface): void => {
  $validator?.errors.clear();
  newJob.value = setObject(newJob.value, job);
  editJobModalVisible.value = true;
};

const handleUpdateJob = (): void => {
  $validator?.validateAll("edit-job").then((result) => {
    if (result) {
      emits("update-job", newJob.value);
      editJobModalVisible.value = false;
    }
  });
};

const showDeleteWorkExperienceModal = (job: any): void => {
  showDeleteExperienceModal(instance?.proxy.$confirm, job, "work experience", () => {
    emits("destroy-job", job.id);
  });
};

const handleUpdateWorkExperience = (job: WorkExperienceInterface) => {
  newJob.value = job;
  handleUpdateJob();
};

const showAnswerWorkExperienceDescriptionForm = (job: WorkExperienceInterface): void => {
  newJob.value = job;
  answerWorkExperienceDescriptionFormModalVisible.value = true;
};

const setWorkExperienceDescriptionFormSent = (sent: boolean): void => {
  workExperienceDescriptionFormSent.value = sent;
  emits("work-experience-description-form-sent", newJob.value.title);
};

const onDateChange = (value: any, attribute: string): void => {
  (newJob.value as any)[attribute] = value ? value.format("YYYY-MM-DD") : null;
};

// Cannot have start_date in future or after end_date.
const disableStartDate = (current: any): any => {
  const today = moment().endOf("day");
  const isAfterEndDate =
    current && newJob.value.end_date && current > moment(newJob.value.end_date).endOf("day");
  const isInFuture = current && moment(current).isAfter(today);
  return isAfterEndDate || isInFuture;
};

// Cannot have end_date in future or before start_date.
const disableEndDate = (current: any): any => {
  const today = moment().endOf("day");
  const isBeforeStartDate =
    current && newJob.value.start_date && current < moment(newJob.value.start_date).startOf("day");
  const isInFuture = current && moment(current).isAfter(today);
  return isBeforeStartDate || isInFuture;
};
</script>
