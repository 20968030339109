<template>
  <div>
    <a-form layout="horizontal" :data-vv-scope="ReportType.PARENTAL">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('parental-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('parental-report.start_date')"
        label="Start date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newReport.start_date)"
          :disabled-date="disabledDateParentalStart"
          class="w-100"
          data-vv-name="start_date"
          data-vv-as="start date"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('parental-report.end_date') ? 'error' : ''"
        :help="$validator.errors.first('parental-report.end_date')"
        label="End date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newReport.end_date)"
          :disabled-date="disabledDateParentalEnd"
          class="w-100"
          data-vv-name="end_date"
          data-vv-as="end date"
          @change="(d) => onDatesChange(d, 'end_date')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Percentage">
        <a-radio-group
          :default-value="'1'"
          :value="newReport.parental_percentage"
          @change="(el) => (newReport.parental_percentage = el.target.value)"
        >
          <a-radio :value="'0.25'">25%</a-radio>
          <a-radio :value="'0.5'">50%</a-radio>
          <a-radio :value="'0.75'">75%</a-radio>
          <a-radio :value="'1'">100%</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="newReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>
    </a-form>

    <div class="d-flex justify-content-center mt-4">
      <a-button type="primary" style="width: 50%" @click="handleStoreReport()">Submit</a-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, getCurrentInstance, toRefs } from "vue";
import { disabledStartDates, disabledEndDates } from "../_utils/disabled-dates";
import { ReportInterface, ReportPayloadInterface, ReportType } from "../types";
import { ProfileInterface } from "@/modules/authentication/types";
import useMixin from "@/useMixin";
import { Moment } from "moment";
import moment from "@/date";
import useFilters from "@/useFilters";
import { emitter } from "@/mitt";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  userParentalReports: { type: Array as () => Array<ReportInterface>, default: () => [] },
  selectedDay: { type: String, default: undefined },
});

// Emits
const emits = defineEmits(["day-selected", "store-report"]);

// Mixins
const { setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const { user, selectedDay } = toRefs(props);
const newReport = ref<ReportPayloadInterface>({
  user_id: user.value.id,
  type: ReportType.PARENTAL,
  start_date: moment(selectedDay.value).format("YYYY-MM-DD"),
  end_date: undefined,
  comment: undefined,
  parental_percentage: 1,
});

// Life-cycle Hooks
onMounted(() => {
  emitter.on("selected-date", (date) => handleSelectedDate(date));
});

onUnmounted(() => {
  emitter.off("selected-date", (date) => handleSelectedDate(date));
});

// Component Methods
const handleSelectedDate = (date: any): void => {
  if (!disabledDateParentalStart(date)) {
    newReport.value.start_date = moment(date).format("YYYY-MM-DD");
  }
};

const onDatesChange = (date: any, attribute: string): void => {
  if (!date) {
    newReport.value[attribute] = undefined;
  } else {
    newReport.value[attribute] = date.format("YYYY-MM-DD");

    if (attribute === "start_date") {
      emits("day-selected", newReport.value[attribute]);
    }

    if (attribute === "start_date" && !newReport.value["end_date"]) {
      newReport.value["end_date"] = date.format("YYYY-MM-DD");
    } else if (attribute === "end_date" && !newReport.value["start_date"]) {
      newReport.value["start_date"] = date.format("YYYY-MM-DD");
      emits("day-selected", newReport.value[attribute]);
    }
  }
};

const handleStoreReport = (): void => {
  $validator?.validateAll(ReportType.PARENTAL).then((result) => {
    if (result) {
      emitReportAndClearFields();
    }
  });
};

const emitReportAndClearFields = (): void => {
  emits("store-report", newReport.value);
  const previous_start_date = newReport.value.start_date;

  newReport.value = setObject(newReport.value, undefined);
  newReport.value.user_id = props.user?.id;
  newReport.value.type = ReportType.PARENTAL;
  newReport.value.start_date = previous_start_date;
  newReport.value.parental_percentage = 1;
};

const disabledDateParentalStart = (current: Moment): boolean => {
  return disabledStartDates(current, newReport.value, props.userParentalReports);
};

const disabledDateParentalEnd = (current: Moment): boolean => {
  return disabledEndDates(current, newReport.value, props.userParentalReports);
};
</script>
