<template>
  <div>
    <a-layout-sider
      :trigger="null"
      :class="{ collapsed: !sidebarCollapsed }"
      class="sidebar-wrapper"
    >
      <!-- LOGO -->
      <div class="sidebar-logo">
        <h2 class="text-white" @click="() => navigate('landing')">Skynet</h2>
      </div>

      <!-- MENU -->
      <a-menu
        :selected-keys="[`${$route.name}`]"
        theme="dark"
        mode="inline"
        class="sidebar-menu"
        @click="({ key }) => navigate(key)"
      >
        <a-menu-item v-if="acl.isInternal" key="dashboard" class="mb-3">
          <a-icon type="dashboard" />
          <span class="font-weight-bold">Dashboard</span>
        </a-menu-item>

        <a-menu-item key="projects">
          <a-icon type="rocket" />
          <span>Projects</span>
        </a-menu-item>

        <a-menu-item v-if="acl.isInternal" key="clients">
          <a-icon type="shop" />
          <span>Clients</span>
        </a-menu-item>

        <a-menu-item v-if="acl.isInternal" key="reports">
          <a-icon type="line-chart" />
          <span>Reports</span>
        </a-menu-item>

        <a-menu-item v-if="acl.isInternal" key="roles">
          <a-icon type="contacts" />
          <span>Roles</span>
        </a-menu-item>

        <a-menu-item v-if="acl.isInternal" key="users">
          <a-icon type="user" />
          <span>Users</span>
        </a-menu-item>

        <a-menu-item v-if="acl.isInternal" key="teams">
          <a-icon type="team" />
          <span>Teams</span>
        </a-menu-item>

        <a-menu-item key="job-postings">
          <a-icon type="solution" />
          <span>Job Postings</span>
        </a-menu-item>

        <a-menu-item v-if="acl.isInternal" key="cvs">
          <a-icon type="profile" />
          <span>CVs</span>
        </a-menu-item>

        <a-menu-item key="receipts">
          <a-icon type="shopping-cart" />
          <span>Receipts</span>
        </a-menu-item>

        <a-menu-item v-if="acl.isInternal" key="skp">
          <a-icon type="wallet" />
          <span>SKP</span>
        </a-menu-item>

        <a-menu-item key="skills">
          <a-icon type="tool" />
          <span>Skills</span>
        </a-menu-item>

        <a-menu-item key="week-schedule">
          <a-icon type="schedule" />
          <span>Week Schedule</span>
        </a-menu-item>

        <a-sub-menu v-if="acl.isAdmin">
          <template #title>
            <span>
              <a-icon type="question" />
              <span>Quiz</span>
            </span>
          </template>

          <a-menu-item key="quiz">
            <a-icon type="question" />
            <span>Questions</span>
          </a-menu-item>

          <a-menu-item key="tags">
            <a-icon type="file-text" />
            <span>Tags</span>
          </a-menu-item>
        </a-sub-menu>

        <a-menu-item v-else key="quiz">
          <a-icon type="question" />
          <span>Quiz</span>
        </a-menu-item>

        <a-sub-menu v-if="acl.isFinancial || acl.isAdmin">
          <template #title>
            <span>
              <a-icon type="dollar" />
              <span>Financial</span>
            </span>
          </template>

          <a-menu-item v-if="acl.isFinancial" key="invoices">
            <a-icon type="file-text" />
            <span>Invoices</span>
          </a-menu-item>

          <a-menu-item v-if="acl.isFinancial || acl.isAdmin" key="transactions">
            <a-icon type="credit-card" />
            <span>Transactions</span>
          </a-menu-item>

          <a-menu-item v-if="acl.isFinancial" key="cost-centers">
            <a-icon type="gold" />
            <span>Cost Centers</span>
          </a-menu-item>

          <a-menu-item v-if="acl.isFinancial" key="recurring-payments">
            <a-icon type="reload" />
            <span>Recurring Payments</span>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu v-if="acl.isAdmin">
          <template #title>
            <span>
              <a-icon type="coffee" />
              <span>Admin</span>
            </span>
          </template>

          <a-menu-item key="consultant-profiles">
            <a-icon type="idcard" />
            <span>Consultant Profiles</span>
          </a-menu-item>

          <a-menu-item key="audits">
            <a-icon type="audit" />
            <span>Audits</span>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>

      <div class="sidebar-footer text-grey">
        <div v-if="profile?.roles.length == 1">
          <p v-if="profile?.roles[0] == 'default'" class="mb-0">
            Role:
            <strong>employee</strong>
          </p>
          <p v-else class="mb-0">
            Role:
            <strong>{{ profile?.roles[0] }}</strong>
          </p>
        </div>
        <div v-else>
          <p class="mb-0">
            Role:
            <a-popover title="Assigned Roles">
              <template #content>
                <p v-for="r in profile?.roles" :key="r">
                  {{ r == "default" ? "EMPLOYEE" : r.toUpperCase() }}
                </p>
              </template>
              <strong>Multiple</strong>
            </a-popover>
          </p>
        </div>
        <p v-if="env.VITE_ENV !== 'production'" class="mb-0">
          Env:
          <strong>{{ env.VITE_ENV }}</strong>
        </p>
      </div>
    </a-layout-sider>

    <div class="sidebar-filler" />
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed } from "vue";
import { BaseAclInterface, ProfileInterface } from "../../authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";

// Props
const props = defineProps({
  sidebarCollapsed: { type: Boolean, default: false },
  acl: { type: Object as () => BaseAclInterface, default: () => ({}) },
  users: { type: Array, default: () => [] },
});

// Emits
const emits = defineEmits(["sidebar-clicked"]);

// Pinia
const authenticationStore = useAuthenticationStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);

// Instances
const instance = getCurrentInstance();
const $router = instance?.proxy.$router;

// Data Properties
const env = ref<any>(import.meta.env);

const navigate = (key: string): void => {
  if (key === "landing" || key === "dashboard") {
    $router?.push({ name: key, hash: "#reports" });
  } else {
    $router?.push({ name: key });
  }
  emits("sidebar-clicked", key);
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  position: fixed;
  height: 100vh;
  z-index: 5;
  left: -200px;
  @media (min-width: 768px) {
    position: fixed;
    left: 0;
  }
}

.sidebar-wrapper.collapsed {
  left: 0;
}

.sidebar-filler {
  width: 200px;
  height: 100vh;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.sidebar-logo {
  height: 32px;
  margin: 16px;
  text-align: center;
  cursor: pointer;
}

.sidebar-menu {
  overflow-y: auto;
  flex: 1;
}

.sidebar-footer {
  margin: 16px;
}
</style>

<style lang="scss">
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
</style>
