<template>
  <div class="mr-4 mb-4" :style="{ width: '50%' }">
    <div class="d-flex flex-column px-2 pb-2 mb-3" :style="{ borderBottom: '1px solid grey' }">
      <strong class="mb-1">{{ title }}</strong>
      <span>{{ jobPostings.length }}{{ pagination.has_more_data ? "+" : "" }} postings</span>
    </div>

    <div
      class="pb-2"
      :style="{ maxHeight: maxHeight, overflow: 'auto', borderBottom: '1px solid grey' }"
    >
      <div v-for="jobPosting in jobPostings" :key="jobPosting.id">
        <job-posting-card
          :job-posting="jobPosting"
          :selected="jobPosting.id === selectedJobPosting?.id"
        />
      </div>

      <div
        v-if="pagination.has_more_data || loading"
        class="pt-3 pb-2 text-center"
        :style="{ cursor: 'pointer' }"
        @click="() => $emit('load-more-job-postings')"
      >
        <a-spin v-if="loading" />
        <div v-else>
          <a-icon type="plus" class="mr-2" />
          <span><strong>Load more…</strong></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from "vue";
import JobPostingCard from "./JobPostingCard.vue";
import { JobPostingInterface } from "../types";
import { useJobPostingsStore, JobPostingsPaginationInterface } from "@/modules/job_postings/_store";

// Props
const props = defineProps({
  title: { type: String as () => "New" | "Ongoing", default: undefined },
  jobPostings: { type: Array as () => Array<JobPostingInterface>, default: () => [] },
  pagination: { type: Object as () => JobPostingsPaginationInterface, default: undefined },
  loading: { type: Boolean, default: false },
});

// Pinia
const jobPostingsStore = useJobPostingsStore();
const selectedJobPosting = computed<JobPostingInterface | undefined>(
  () => jobPostingsStore.selectedJobPosting
);

// Data properties
const maxHeight = ref<string>("0px");

// Lifecycle methods
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// Class methods
const handleResize = () => {
  maxHeight.value = `${window.innerHeight - 270}px`;
};
</script>
