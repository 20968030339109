<template>
  <a-modal
    :visible="true"
    :destroy-on-close="true"
    :width="900"
    title="Answer a new profile description form"
    ok-text="Send"
    @ok="handleSendProfileDescriptionForm"
    @cancel="$emit('close')"
  >
    <a-form data-vv-scope="profile-description-form-modal" layout="horizontal">
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="1. User">
        <p>{{ user.name }}</p>
      </a-form-item>

      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="2. Age">
        <a-textarea v-model="newProfileDescriptionForm.q2" :auto-size="{ minRows: 1 }" />
      </a-form-item>

      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="3. Education">
        <a-textarea v-model="newProfileDescriptionForm.q3" :auto-size="{ minRows: 1 }" />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="4. When you got onboard Skry?"
      >
        <a-textarea
          v-model="newProfileDescriptionForm.q4"
          :auto-size="{ minRows: 1 }"
          placeholder="E.g. After studies. After 3 roles as a dev and PM."
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="5. What are you passionate about? What are some of the values that define you?"
      >
        <a-textarea
          v-model="newProfileDescriptionForm.q5"
          :auto-size="{ minRows: 1 }"
          placeholder="E.g. I'm a quality driven person. I deeply care about how other people feel. I want things to be fair. I care about sustainability"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="6. Hobbies">
        <a-textarea v-model="newProfileDescriptionForm.q6" :auto-size="{ minRows: 1 }" />
      </a-form-item>

      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="7. Volunteering">
        <a-textarea v-model="newProfileDescriptionForm.q7" :auto-size="{ minRows: 1 }" />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="8. Other interesting things that you think has defined you as a person?"
      >
        <a-textarea v-model="newProfileDescriptionForm.q8" :auto-size="{ minRows: 1 }" />
      </a-form-item>
    </a-form>

    <!-- Spinner -->
    <div v-if="spin" class="p-2">
      <a-spin tip="Sending...">
        <div class="spin-content"></div>
      </a-spin>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, getCurrentInstance } from "vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import { apiSendProfileDescriptionForm } from "@/modules/users/_utils/api";
import { ProfileDescriptionFormInterface } from "../../users/types";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits(["set-profile-description-form-sent", "close"]);

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;
const $validator = instance?.proxy.$validator;

// Data properties
const spin = ref(false);
const sent = ref(false);
const newProfileDescriptionForm = ref<ProfileDescriptionFormInterface>({
  q2: "",
  q3: "",
  q4: "",
  q5: "",
  q6: "",
  q7: "",
  q8: "",
});

// Lifecycle hooks
onMounted(() => {
  if (localStorage.newProfileDescriptionForm) {
    newProfileDescriptionForm.value = JSON.parse(localStorage.newProfileDescriptionForm);
  }
});

onBeforeUnmount(() => {
  if (sent.value) {
    return;
  }

  const editedFields =
    newProfileDescriptionForm.value.q2 ||
    newProfileDescriptionForm.value.q3 ||
    newProfileDescriptionForm.value.q4 ||
    newProfileDescriptionForm.value.q5 ||
    newProfileDescriptionForm.value.q6 ||
    newProfileDescriptionForm.value.q7 ||
    newProfileDescriptionForm.value.q8;

  if (editedFields) {
    localStorage.setItem(
      "newProfileDescriptionForm",
      JSON.stringify(newProfileDescriptionForm.value)
    );
  }
});

// Class methods
const handleSendProfileDescriptionForm = (): void => {
  $validator?.validate().then((result) => {
    if (result) {
      sendProfileDescriptionForm(newProfileDescriptionForm.value);
    }
  });
};

const sendProfileDescriptionForm = (
  newProfileDescriptionForm: ProfileDescriptionFormInterface
): void => {
  spin.value = true;

  apiSendProfileDescriptionForm(props.user.id, newProfileDescriptionForm)
    .then(() => {
      emits("set-profile-description-form-sent");
      $message?.success("Profile description form sent successfully!", 5);
      exitModal();
    })
    .catch(() => {
      $message?.error("Couldn't send profile description form. Try again.", 5);
    })
    .finally(() => (spin.value = false));
};

const exitModal = () => {
  localStorage.removeItem("newProfileDescriptionForm");
  sent.value = true;
  emits("close");
};
</script>
