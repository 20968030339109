<template>
  <div>
    <a-form layout="horizontal" :data-vv-scope="ReportType.ABSENCE">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('absence-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('absence-report.start_date')"
        label="Start date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newReport.start_date)"
          :disabled-date="handleDisabledStartDates"
          class="w-100"
          data-vv-name="start_date"
          data-vv-as="start date"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('absence-report.end_date') ? 'error' : ''"
        :help="$validator.errors.first('absence-report.end_date')"
        label="End date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newReport.end_date)"
          :disabled-date="handleDisabledEndDates"
          class="w-100"
          data-vv-name="end_date"
          data-vv-as="end date"
          @change="(d) => onDatesChange(d, 'end_date')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="newReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>
    </a-form>

    <div class="d-flex justify-content-center mt-4">
      <a-button type="primary" style="width: 50%" @click="handleStoreReport()">Submit</a-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, getCurrentInstance, toRefs } from "vue";
import { disabledStartDates, disabledEndDates } from "../_utils/disabled-dates";
import { ReportInterface, ReportPayloadInterface, ReportType } from "../types";
import { ProfileInterface } from "@/modules/authentication/types";
import useMixin from "@/useMixin";
import { Moment } from "moment";
import moment from "@/date";
import useFilters from "@/useFilters";
import { emitter } from "@/mitt";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  selectedDay: { type: String, default: undefined },
  userVacationAndAbsenceReports: { type: Array as () => Array<ReportInterface>, default: () => [] },
});

// Emits
const emits = defineEmits(["day-selected", "store-report"]);

// Mixins
const { setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const intsance = getCurrentInstance();
const $validator = intsance?.proxy.$validator;

// Data Properties
const { user, selectedDay } = toRefs(props);
const newReport = ref<ReportPayloadInterface>({
  user_id: user.value.id,
  start_date: moment(selectedDay.value).format("YYYY-MM-DD"),
  end_date: undefined,
  type: ReportType.ABSENCE,
});

// Life-cycle Hooks
onMounted(() => {
  emitter.on("selected-date", (date) => handleSelectedDate(date));
});

onUnmounted(() => {
  emitter.off("selected-date", (date) => handleSelectedDate(date));
});

// Class Methods
const handleSelectedDate = (date: any): void => {
  if (!handleDisabledStartDates(moment(date))) {
    newReport.value.start_date = moment(date).format("YYYY-MM-DD");
  }
};

const onDatesChange = (date: any, attribute: string): void => {
  newReport.value[attribute] = date ? date.format("YYYY-MM-DD") : undefined;

  if (attribute === "start_date") {
    emits("day-selected", newReport.value[attribute]);
  }

  if (attribute === "start_date" && !newReport.value["end_date"]) {
    newReport.value["end_date"] = date.format("YYYY-MM-DD");
  } else if (attribute === "end_date" && !newReport.value["start_date"]) {
    newReport.value["start_date"] = date.format("YYYY-MM-DD");
    emits("day-selected", newReport.value[attribute]);
  }
};

const handleStoreReport = (): void => {
  $validator?.validateAll(ReportType.ABSENCE).then((result) => {
    if (result) {
      emitReportAndClearFields();
    }
  });
};

const emitReportAndClearFields = (): void => {
  emits("store-report", newReport.value);
  const previous_start_date = newReport.value.start_date;

  newReport.value = setObject(newReport.value, undefined);
  newReport.value.user_id = props.user?.id;
  newReport.value.type = ReportType.ABSENCE;
  newReport.value.start_date = previous_start_date;
};

const handleDisabledStartDates = (current: Moment): boolean => {
  return disabledStartDates(current, newReport.value, props.userVacationAndAbsenceReports);
};

const handleDisabledEndDates = (current: Moment): boolean => {
  return disabledEndDates(current, newReport.value, props.userVacationAndAbsenceReports);
};
</script>
