import { ProfileInterface } from "@/modules/authentication/types";
import { RoleDescriptionFormInterface, RoleInterface } from "@/modules/roles/types";
import {
  ProfileDescriptionFormInterface,
  WorkExperienceDescriptionFormInterface,
} from "@/modules/users/types";
import axios from "@/network";

export function apiGetUsers(params: any, headers = {}): any {
  const url = "/users";
  return axios.get(url, { params, headers });
}

export function apiGetUser(userId: number): any {
  const url = `/users/${userId}`;
  return axios.get(url);
}

export function apiUpdateUser(userId: number, updatedUser: ProfileInterface): any {
  const url = `/users/${userId}`;
  const payload = { user: updatedUser };
  return axios.patch(url, payload);
}

export function apiStoreExperience(type: any, userId: number, newExperience: any): any {
  let payload = null;
  switch (type) {
    case "educations":
      payload = { education: newExperience };
      break;
    case "knowledge_domains":
      payload = { knowledge_domain_user: newExperience };
      break;
    case "merits":
      payload = { merit: newExperience };
      break;
    case "work_experiences":
      payload = { work_experience: newExperience };
      break;
    default:
      throw `Could not store the experience because of unknown type '${type}'`;
  }
  const url = `/users/${userId}/${type}`;
  return axios.post(url, payload);
}

export function apiUpdateExperience(
  type: any,
  userId: number,
  experienceId: number,
  updatedExperience: any
): any {
  let payload = null;
  switch (type) {
    case "educations":
      payload = { education: updatedExperience };
      break;
    case "knowledge_domains":
      payload = { knowledge_domain_user: updatedExperience };
      break;
    case "merits":
      payload = { merit: updatedExperience };
      break;
    case "work_experiences":
      payload = { work_experience: updatedExperience };
      break;
    default:
      throw `Could not update the experience because of unknown type '${type}'`;
  }
  const url = `/users/${userId}/${type}/${experienceId}`;
  return axios.patch(url, payload);
}

export function apiDestroyExperience(type: any, userId: number, experienceId: any): any {
  const url = `/users/${userId}/${type}/${experienceId}`;
  return axios.delete(url);
}

export function apiGetInterests(): any {
  const url = "/interests";
  return axios.get(url);
}

export function apiUpdateUserInterests(userId: number, updatedInterests: Array<any>): any {
  const url = `/users/${userId}/interests`;
  const payload = { interests: updatedInterests };
  return axios.patch(url, payload);
}

export function apiGetUserPermissions(): any {
  const url = "/user_roles";
  return axios.get(url);
}

export function apiGetUserRoles(userId: number, params?: any): any {
  const url = `/users/${userId}/roles`;
  return axios.get(url, { params });
}

export function apiGetUserRole(userId: number, roleId: number, params?: any): any {
  const url = `/users/${userId}/roles/${roleId}`;
  return axios.get(url, { params });
}

export function apiUpdateUserRole(
  projectId: number,
  roleId: number,
  updatedRole: RoleInterface
): any {
  const url = `/projects/${projectId}/roles/${roleId}`;
  const payload = { role: updatedRole };
  return axios.patch(url, payload);
}

export function apiDestroyUserRole(projectId: number, roleId: number): any {
  const url = `/projects/${projectId}/roles/${roleId}`;
  return axios.delete(url);
}

export function apiUpdateUserReceipt(updatedReceipt: any): any {
  const url = `users/${updatedReceipt.user.id}/receipts/${updatedReceipt.id}`;
  const payload = { receipt: updatedReceipt };
  return axios.patch(url, payload);
}

export function apiSendProfileDescriptionForm(
  userId: number,
  newProfileDescriptionForm: ProfileDescriptionFormInterface
): any {
  const url = `/users/${userId}/profile_description_forms`;
  return axios.post(url, newProfileDescriptionForm);
}

export function apiGetProfileDescriptionFormAnswers(user_id: number) {
  const url = `users/${user_id}/profile_description_forms`;
  return axios.get(url);
}

export function apiSendRoleDescriptionForm(
  newRoleDescrptionForm: RoleDescriptionFormInterface
): any {
  const url = `/roles/${newRoleDescrptionForm.role_id}/role_description_forms`;
  return axios.post(url, newRoleDescrptionForm);
}

export function apiSendWorkExperienceDescriptionForm(
  user: ProfileInterface,
  newWorkExperienceDescriptionForm: WorkExperienceDescriptionFormInterface
): any {
  const url = `/users/${user.id}/work_experiences/${newWorkExperienceDescriptionForm.work_experience_id}/description_forms`;
  return axios.post(url, newWorkExperienceDescriptionForm);
}

export function apiGetVacationSummary(user_id: number) {
  const url = `/users/${user_id}/vacation_summary`;
  return axios.get(url);
}

export function apiGetAllVacationYears(user_id: number) {
  const url = `/users/${user_id}/vacation_years`;
  return axios.get(url);
}

export function apiGetLockSummary(user_id: number, start_date: string, end_date: string) {
  const url = `/users/${user_id}/lock_summary`;
  const params = { start_date: start_date, end_date: end_date };
  return axios.get(url, { params });
}

export function apiGetReports(params = {}, headers = {}) {
  const url = "/reports";
  return axios.get(url, { params, headers });
}
