<template>
  <a-modal
    :visible="true"
    :destroy-on-close="true"
    :width="900"
    title="Answer a new role description form"
    ok-text="Send"
    @ok="handleSendRoleDescriptionForm"
    @cancel="$emit('close')"
  >
    <a-form data-vv-scope="role-description-form-modal" layout="horizontal">
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
        <strong> Skills </strong>
        <br />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="Are the skills of the role up to date?"
      >
        <a-select
          v-model="newRoleDescriptionForm.skill_ids"
          mode="multiple"
          show-search
          placeholder="Select skills"
          option-filter-prop="children"
        >
          <a-select-option v-for="s in skills" :key="s.id" :class="{ 'already-in': !!s.skill_id }">
            {{ s.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <hr style="padding=20px" />
      <br />
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
        <strong> Role description form </strong>
        <br />
      </a-form-item>
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="1. User">
        <p>{{ role.user.name }}</p>
      </a-form-item>
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="2. Client name">
        <p>{{ role.project.client.name }}</p>
      </a-form-item>
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="3. Role title">
        <p>{{ role.title }}</p>
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="4. Client Business Sector"
      >
        <a-textarea v-model="newRoleDescriptionForm.q4" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" label="5. Client's need">
        <a-textarea v-model="newRoleDescriptionForm.q5" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="6. How did you address their needs?"
      >
        <a-textarea v-model="newRoleDescriptionForm.q6" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="7. How did your work answer their needs?"
      >
        <a-textarea v-model="newRoleDescriptionForm.q7" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="8. How will the work you did affect the future of the client or this particular case?"
      >
        <a-textarea v-model="newRoleDescriptionForm.q8" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="9. Will there be lasting change as a result of your work?"
      >
        <a-textarea v-model="newRoleDescriptionForm.q9" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="10. How happy was the client with your involvement?"
      >
        <a-textarea v-model="newRoleDescriptionForm.q10" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="11. How well did you work with the team?"
      >
        <a-textarea v-model="newRoleDescriptionForm.q11" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="12. Would you like to do something similar again? Which part was the most interesting?"
      >
        <a-textarea v-model="newRoleDescriptionForm.q12" :auto-size="{ minRows: 1 }" />
      </a-form-item>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        label="13. Current description"
      >
        <p>
          {{ role.description && role.description.length > 0 ? role.description : "< empty >" }}
        </p>
      </a-form-item>
    </a-form>

    <!-- Spinner -->
    <div v-if="spin" style="padding: 20px">
      <a-spin tip="Sending...">
        <div class="spin-content"></div>
      </a-spin>
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, getCurrentInstance } from "vue";
import { RoleDescriptionFormInterface, RoleInterface } from "@/modules/roles/types";
import { apiSendRoleDescriptionForm } from "@/modules/projects/_utils/api";
import { SkillInterface } from "@/modules/skills/types";

// Props
const props = defineProps({
  role: { type: Object as () => RoleInterface, default: undefined },
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
});

// Emits
const emits = defineEmits(["get-skills", "role-description-form-sent", "close"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;
const $message = instance?.proxy.$message;

// Data properties
const spin = ref<Boolean>(false);
const sent = ref<Boolean>(false);
const newRoleDescriptionForm = ref<RoleDescriptionFormInterface>({
  role_id: undefined,
  skill_ids: undefined,
  q4: "",
  q5: "",
  q6: "",
  q7: "",
  q8: "",
  q9: "",
  q10: "",
  q11: "",
  q12: "",
});

// Lifecycle hooks
onMounted(() => {
  emits("get-skills");

  const dataIsInLocalStorage =
    localStorage.newRoleDescriptionForm &&
    JSON.parse(localStorage.newRoleDescriptionForm).role_id === props.role.id;

  if (dataIsInLocalStorage) {
    newRoleDescriptionForm.value = JSON.parse(localStorage.newRoleDescriptionForm);
  } else {
    newRoleDescriptionForm.value.role_id = props.role.id;
    newRoleDescriptionForm.value.skill_ids = props.role.skills.map((skill) => skill.id);
  }
});

onBeforeUnmount(() => {
  if (sent.value) {
    return;
  }

  const editedSkills =
    newRoleDescriptionForm.value.skill_ids?.length === props.role.skills.length &&
    newRoleDescriptionForm.value.skill_ids?.every((id) => props.role.skills.includes(id));
  const editedFields =
    newRoleDescriptionForm.value.q4 ||
    newRoleDescriptionForm.value.q5 ||
    newRoleDescriptionForm.value.q6 ||
    newRoleDescriptionForm.value.q7 ||
    newRoleDescriptionForm.value.q8 ||
    newRoleDescriptionForm.value.q9 ||
    newRoleDescriptionForm.value.q10 ||
    newRoleDescriptionForm.value.q11 ||
    newRoleDescriptionForm.value.q12;

  if (editedSkills || editedFields) {
    localStorage.setItem("newRoleDescriptionForm", JSON.stringify(newRoleDescriptionForm.value));
  }
});

// Class methods
const handleSendRoleDescriptionForm = (): void => {
  $validator?.validate().then((result) => {
    if (result) {
      sendRoleDescriptionForm(newRoleDescriptionForm.value);
    }
  });
};

const sendRoleDescriptionForm = (newRoleDescriptionForm: RoleDescriptionFormInterface): void => {
  spin.value = true;

  apiSendRoleDescriptionForm(newRoleDescriptionForm)
    .then(() => {
      emits("role-description-form-sent", newRoleDescriptionForm.skill_ids);
      $message?.success("Role description form sent successfully!", 5);
      exitModal();
    })
    .catch(() => {
      $message?.error("Couldn't send role description form. Try again.", 5);
    })
    .finally(() => (spin.value = false));
};

const exitModal = () => {
  localStorage.removeItem("newRoleDescriptionForm");
  sent.value = true;
  emits("close");
};
</script>
