import { defineStore, acceptHMRUpdate } from "pinia";
import { StateInterface, SurveyPayloadInterface } from "@/modules/reports/types";
import { mergePublicHolidays } from "@/modules/reports/_utils/public-holidays";
import axios from "@/network";
import {
  apiGetReports,
  apiGetCalendarReports,
  apiStoreReport,
  apiUpdateReport,
  apiDestroyReport,
  apiLockUserReports,
  apiStoreSurveyResponse,
  apiGetPublicHolidays,
  apiGetAnnualPublicHolidays,
} from "@/modules/reports/_utils/api";

const headers = { "Cache-Control": "no-cache" };

export const useReportsStore = defineStore("reports", {
  state: (): StateInterface => {
    return {
      reports: new Map(),
      reportsSummary: [],
      subprojectsSummary: [],
      calendarReports: new Map(),
      publicHolidays: new Map(),
      cost_centers: [],
      reportsPagination: {},
    };
  },
  actions: {
    getReportsByPage(params: any) {
      return new Promise((resolve, reject) => {
        apiGetReports(params, headers)
          .then((res: any) => {
            this.setReports({
              page: params.page,
              payload: res.data.data,
            });
            this.setReportsPagination(res.data.meta);
            if (res.data.meta.reports_summary) {
              this.setReportsSummary(res.data.meta.reports_summary);
            } else {
              this.clearReportsSummary();
            }
            if (res.data.meta.subprojects_summary) {
              this.setSubprojectsSummary(res.data.meta.subprojects_summary);
            } else {
              this.clearSubprojectsSummary();
            }
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    clearReports() {
      this.reports = new Map();
    },

    getCalendarReports(month: any) {
      return new Promise((resolve, reject) => {
        apiGetCalendarReports(month)
          .then((res: any) => {
            this.setCalendarReports({ month, reports: res.data.data });
            resolve(res.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    getPublicHolidays(month: any) {
      return new Promise((resolve, reject) => {
        apiGetPublicHolidays(month)
          .then((res: any) => {
            const mergedPublicHolidays = mergePublicHolidays(res.data.data);
            this.setPublicHolidays({ month, publicHolidays: mergedPublicHolidays });
            resolve(res.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },
    getPublicAnnualHolidays(year: any) {
      return new Promise((resolve, reject) => {
        apiGetAnnualPublicHolidays(year)
          .then((res: any) => {
            const mergedPublicHolidays = mergePublicHolidays(res.data.data);
            this.setPublicHolidays({ year, publicHolidays: mergedPublicHolidays });
            resolve(res.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    storeReport(newReport: any) {
      return new Promise((resolve, reject) => {
        apiStoreReport(newReport)
          .then((res: any) => {
            if ("meta" in res.data && "prepaid_days_message" in res.data.meta) {
              res.data.data.prepaid_message = res.data.meta.prepaid_days_message;
            } else if (
              "meta" in res.data &&
              "warning_sick_report_creation_message" in res.data.meta
            ) {
              res.data.data.sick_report_warning =
                res.data.meta.warning_sick_report_creation_message;
            } else if (
              "meta" in res.data &&
              "warning_time_report_creation_message" in res.data.meta
            ) {
              res.data.data.time_report_warning =
                res.data.meta.warning_time_report_creation_message;
            }
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    updateReport({ reportId, updatedReport }: any) {
      return new Promise((resolve, reject) => {
        apiUpdateReport(reportId, updatedReport)
          .then((res: any) => {
            if ("meta" in res.data && "prepaid_days_message" in res.data.meta) {
              res.data.data.prepaid_message = res.data.meta.prepaid_days_message;
            }
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    destroyReport(reportId: any) {
      return new Promise((resolve, reject) => {
        apiDestroyReport(reportId)
          .then((res: any) => resolve(res.data.data))
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    lockUserReports({ userId, lockingDate }: any) {
      return new Promise((resolve, reject) => {
        apiLockUserReports(userId, lockingDate)
          .then((res: any) => {
            this.getUserReports({ userId, page: 1 });
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    getUserReports({ userId, page = 1 }: any) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${userId}/reports?page=${page}`)
          .then((res: any) => {
            this.setReports({ page: page, payload: res.data.data });
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    storeSurveyResponse(newSurveyResponse: SurveyPayloadInterface) {
      return new Promise((resolve, reject) => {
        apiStoreSurveyResponse(newSurveyResponse)
          .then((res: any) => {
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    setCalendarReports({ month, reports }: any): void {
      var map = new Map();
      this.calendarReports.forEach((v, k) => {
        map.set(k, v);
      });
      map.set(month, reports);
      this.calendarReports = map;
    },

    setPublicHolidays({ month, publicHolidays }: any): void {
      var map = new Map();
      this.publicHolidays.forEach((v, k) => {
        map.set(k, v);
      });
      map.set(month, publicHolidays);
      this.publicHolidays = map;
    },

    setReports({ page, payload }: any) {
      var map = new Map();
      this.reports.forEach((v, k) => {
        map.set(k, v);
      });
      map.set(page, payload);
      this.reports = map;
    },

    setReportsSummary(reportsSummary: any) {
      this.reportsSummary = reportsSummary;
    },

    clearReportsSummary() {
      this.reportsSummary = [];
    },

    setSubprojectsSummary(subprojectsSummary: any) {
      this.subprojectsSummary = subprojectsSummary;
    },

    clearSubprojectsSummary() {
      this.subprojectsSummary = [];
    },

    setReportsPagination(meta: any) {
      this.reportsPagination = meta;
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReportsStore, import.meta.hot));
}
