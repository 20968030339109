<template>
  <div class="card">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="mb-3">Profile Description</h2>
      <div class="d-flex justify-content-end">
        <a-tooltip v-if="acl.isOwner">
          <template #title> Fill a new profile description form </template>
          <a-icon
            type="file-text"
            class="action-default"
            @click="showAnswerProfileDescriptionForm()"
          />
        </a-tooltip>
        <a-divider v-if="editEnabled && acl.isOwner" type="vertical" />
        <a-tooltip v-if="editEnabled && (acl.isOwner || acl.isAdmin)">
          <template #title> Edit profile description </template>
          <a-icon type="edit" class="action-default" @click="editingProfileDescription = true" />
        </a-tooltip>
      </div>
    </div>

    <!-- EDITABLE TEXT AREA -->
    <edit-text-area
      v-if="editingProfileDescription"
      :text="user.profile_description"
      :reset-text="user.profile_description"
      @save="(text) => handleSaveProfileDescription(text)"
      @cancel="editingProfileDescription = false"
    />

    <div v-else>
      <!-- PARSED PROFILE DESCRIPTION -->
      <p v-if="user.profile_description" class="mb-3">{{ user.profile_description }}</p>
      <div class="d-flex">
        <br />
        <strong>Latest profile description form answers:</strong>
        <br />
        <a-icon
          v-if="!showProfileDescriptionAnswers"
          type="plus-square"
          style="padding: 5px"
          @click="showProfileDescriptionAnswers = true"
        />
        <a-icon
          v-if="showProfileDescriptionAnswers"
          type="minus-square"
          style="padding: 5px"
          @click="showProfileDescriptionAnswers = false"
        />
      </div>
      <p v-if="!user.profile_description" class="d-flex justify-content-center my-3">
        <em>No description</em>
      </p>
    </div>

    <!-- ANSWER PROFILE DESCRIPTION FORM MODAL -->
    <profile-description-form-modal
      v-if="answerProfileDescriptionFormVisible"
      :acl="acl"
      :user="user"
      @close="answerProfileDescriptionFormVisible = false"
      @set-profile-description-form-sent="setProfileDescriptionFormSent"
    />

    <!-- PROFILE DESCRIPTION FORM ANSWERS -->
    <profile-description-form-answers
      v-if="showProfileDescriptionAnswers"
      :sent="profileDescriptionFormSent"
      :acl="acl"
      :user="user"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import ProfileDescriptionFormAnswers from "../../users/_components/ProfileDescriptionFormAnswers.vue";
import ProfileDescriptionFormModal from "../../users/_components/ProfileDescriptionFormModal.vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import EditTextArea from "../../common/_components/EditTextArea.vue";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  editEnabled: { type: Boolean, default: true },
});

// Emits
const emits = defineEmits(["update-profile", "profile-description-form-sent"]);

// Data Properties
const answerProfileDescriptionFormVisible = ref<boolean>(false);
const showProfileDescriptionAnswers = ref<boolean>(false);
const profileDescriptionFormSent = ref<boolean>(false);
const editingProfileDescription = ref<boolean>(false);
const newProfile = ref<any>({
  profile_description: "",
});

// Life-cycle Hooks
onMounted(() => {
  newProfile.value.profile_description = props.user.profile_description;
});

const handleSaveProfileDescription = (description: string): void => {
  editingProfileDescription.value = false;
  newProfile.value.profile_description = description;
  emits("update-profile", newProfile.value);
};

const showAnswerProfileDescriptionForm = (): void => {
  answerProfileDescriptionFormVisible.value = true;
};

const setProfileDescriptionFormSent = (): void => {
  profileDescriptionFormSent.value = true;
  answerProfileDescriptionFormVisible.value = false;
  emits("profile-description-form-sent");
};
</script>
