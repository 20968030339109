// General routes
import Dashboard from "@/modules/common/_views/Dashboard";
import Forbidden from "@/modules/common/_views/Forbidden";
import NotFound from "@/modules/common/_views/NotFound";

// Module routes
import consultantProfilesRoutes from "@/modules/consultant_profiles/routes";
import authenticationRoutes from "@/modules/authentication/routes";
import recurringJobsRoutes from "@/modules/recurring_jobs/routes";
import weekScheduleRoutes from "@/modules/week_schedule/routes";
import transactionsRoutes from "@/modules/transactions/routes";
import costCentersRoutes from "@/modules/cost_centers/routes";
import jobPostingsRoutes from "@/modules/job_postings/routes";
import projectsRoutes from "@/modules/projects/routes";
import invoicesRoutes from "@/modules/invoices/routes";
import receiptsRoutes from "@/modules/receipts/routes";
import clientsRoutes from "@/modules/clients/routes";
import reportsRoutes from "@/modules/reports/routes";
import skillsRoutes from "@/modules/skills/routes";
import auditsRoutes from "@/modules/audits/routes";
import rolesRoutes from "@/modules/roles/routes";
import usersRoutes from "@/modules/users/routes";
import teamsRoutes from "@/modules/teams/routes";
import homeRoutes from "@/modules/home/routes";
import quizRoutes from "@/modules/quiz/routes";
import quizTagsRoutes from "@/modules/quiz_tags/routes";
import cvRoutes from "@/modules/cv/routes";

export default [
  {
    path: "/",
    component: Dashboard,
    name: "landing",
    redirect: { name: "home" },
    meta: { auth: true },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "dashboard",
    redirect: { name: "home" },
    meta: { auth: true },
    children: [
      ...homeRoutes,
      ...usersRoutes,
      ...teamsRoutes,
      ...cvRoutes,
      ...invoicesRoutes,
      ...projectsRoutes,
      ...clientsRoutes,
      ...reportsRoutes,
      ...receiptsRoutes,
      ...costCentersRoutes,
      ...recurringJobsRoutes,
      ...rolesRoutes,
      ...transactionsRoutes,
      ...skillsRoutes,
      ...auditsRoutes,
      ...weekScheduleRoutes,
      ...consultantProfilesRoutes,
      ...jobPostingsRoutes,
      ...quizRoutes,
      ...quizTagsRoutes,
      {
        path: "forbidden",
        component: Forbidden,
        name: "forbidden",
        meta: { auth: true },
      },
    ],
  },
  ...authenticationRoutes,
  {
    path: "/*",
    component: NotFound,
    name: "not-found",
    meta: { auth: false },
  },
];
