<template>
  <div class="container">
    <span class="flex-center-between mb-3">
      <a
        href="https://docs.google.com/document/d/1t_HgPc6uFseRRhBs8swAK0iJA1rDdT1QODoWA6Uc8OE"
        target="_blank"
        class="ml-1"
        >General guidelines for receipts</a
      >
    </span>

    <!-- RECEIPTS TABLE -->
    <empty-resource-table v-if="!receipts[0] && !loadingTable" resource="receipts" />

    <a-table
      v-if="receipts[0] || loadingTable"
      :columns="receiptsColumns"
      :row-selection="{ selectedRowKeys, onChange: (keys) => (selectedRowKeys = keys) }"
      :data-source="receipts"
      :loading="loadingTable"
      :expand-row-by-click="true"
      :pagination="false"
      :scroll="{ x: 1000 }"
      :row-key="(receipt) => receipt.id"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
    >
      <template #user="userName, r">
        <span class="d-flex align-items-center">
          <span class="min-w-8 mr-2">
            <a-avatar :src="r.user.gravatar" />
          </span>
          <router-link :to="{ name: 'user', params: { id: r.user.id } }" @click.native.stop>
            {{ userName }}
          </router-link>
        </span>
      </template>

      <template #amount="amount">
        <span>{{ toMoney(amount) }}</span>
      </template>

      <template #status="status">
        <span>
          <a-tag :color="receiptStatusColor(status)" class="mb-1 default-cursor">
            <span>{{ receiptStatusLabel(status) }}</span>
          </a-tag>
          <a-tag
            v-if="status === 'approved_pending_payment' || status === 'approved'"
            :color="
              status === 'approved' ? receiptStatusColor('approved') : receiptStatusColor('pending')
            "
            class="default-cursor"
          >
            <span>{{ status === "approved" ? "PAID OUT" : "PENDING PAYMENT" }}</span>
          </a-tag>
        </span>
      </template>

      <template #actions="text, r">
        <span>
          <a-icon
            v-if="r.status !== 'approved' && r.status !== 'approved_pending_payment'"
            type="edit"
            class="action-default"
            @click.stop="handleEditReceiptModalVisible(r)"
          />
        </span>
      </template>

      <template #expandedRowRender="r">
        <div class="m-0">
          <a-col :span="23">
            <receipt-details
              :receipt="r"
              :acl="acl"
              @update-receipt="(payload) => $emit('update-receipt', payload)"
              @store-transaction="(transaction) => $emit('store-transaction', transaction)"
            />
          </a-col>
        </div>
      </template>
    </a-table>

    <a-pagination
      :current="currentPage"
      :total="receiptsPagination ? receiptsPagination.total : 1"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-3"
      @change="(page, pageSize) => handleGetReceiptsPage(page, pageSize)"
      @showSizeChange="(page, newPageSize) => handleGetReceiptsPage(page, newPageSize)"
    />

    <!-- EDIT RECEIPT MODAL -->
    <edit-receipt-modal
      :visible="editReceiptModalVisible"
      :original-receipt="receipt"
      :categories="categories"
      :currencies="currencies"
      :cost-centers="costCenters"
      :users="users"
      :original-attachments="attachments"
      :delete-attachment="handleDeleteAttachment"
      @close="editReceiptModalVisible = false"
      @get-currencies="$emit('get-currencies')"
      @get-categories="$emit('get-categories')"
      @get-cost-centers="$emit('get-cost-centers')"
      @get-users="$emit('get-users')"
      @update-receipt="(editedReceipt) => $emit('update-receipt', editedReceipt)"
    />

    <!-- BULK UPDATE ELEMENTS MODAL -->
    <bulk-update-elements-modal
      :visible="bulkUpdateReceiptsModalVisible"
      element-type="receipt"
      :elements="bulkReceiptsToBeEdited"
      :choices="choices"
      @bulk-update-elements="
        (receiptIds, newStatus) => $emit('bulk-update-receipts', receiptIds, newStatus)
      "
      @clear-selection="selectedRowKeys = []"
      @close="() => $emit('close-bulk-update-receipts-modal')"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { apiGetReceiptAttachments, apiDeleteReceiptAttachment } from "../_utils/api";
import { ReceiptInterface, ReceiptAttachmentInterface } from "../types";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { BaseAclInterface } from "@/modules/authentication/types";
import { MinimalUserInterface } from "@/modules/users/types";
import { TableColumnInterface } from "@/common/types";
import EditReceiptModal from "./EditReceiptModal.vue";
import { receiptStatusLabel } from "../_utils/utils";
import { receiptStatusColor } from "../_utils/utils";
import { receiptsColumns as defaultReceiptsColumns } from "../_utils/tables";
import ReceiptDetails from "./ReceiptDetails.vue";
import useMixin from "@/useMixin";
import BulkUpdateElementsModal, {
  ChoiceInterface,
} from "../../common/_components/BulkUpdateElementsModal.vue";

// Props
const props = defineProps({
  receipts: { type: Array as () => Array<ReceiptInterface>, default: () => [] },
  receiptsPagination: { type: Object, default: undefined },
  currentPage: { type: Number, default: 1 },
  loadingTable: { type: Boolean, default: false },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  categories: { type: Array as () => Array<any>, default: () => [] },
  currencies: { type: Array as () => Array<any>, default: () => [] },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  showEditColumn: { type: Boolean, default: false },
  pageSize: { type: Number, default: 25 },
  bulkUpdateReceiptsModalVisible: { type: Boolean, default: false },
  receiptsColumns: {
    type: Array as () => Array<TableColumnInterface>,
    default: () => defaultReceiptsColumns,
  },
});

// Emits
const emits = defineEmits([
  "set-bulk-update-receipts-button",
  "get-receipts-page",
  "apply-sorting",
]);

// Mixins
const { toMoney } = useMixin();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const editReceiptModalVisible = ref<boolean>(false);
const selectedRowKeys = ref<Array<number>>([]);
const bulkReceiptsToBeEdited = ref<Array<ReceiptInterface>>([]);
const receipt = ref<any>(null);
const attachments = ref<Array<ReceiptAttachmentInterface>>([]);
const choices = ref<Array<ChoiceInterface>>([
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Approved - Pending payment",
    value: "approved_pending_payment",
  },
  {
    label: "Approved - Paid out",
    value: "approved",
  },
]);

// Watchers
watch(selectedRowKeys, () => {
  emits("set-bulk-update-receipts-button", selectedRowKeys.value.length >= 1);
  bulkReceiptsToBeEdited.value = props.receipts.filter(
    (r) => r.id && selectedRowKeys.value.includes(r.id)
  );
});

// Component methods
const handleEditReceiptModalVisible = (r: ReceiptInterface): void => {
  receipt.value = r;
  handleGetAttachments();
};

const handleGetReceiptsPage = (page: number, pageSize: number): void => {
  emits("get-receipts-page", { page, pageSize });
};

const handleChange = (pagination: boolean, filters: any, sorter: any): void => {
  emits("apply-sorting", {
    sort: sorter.columnKey,
    order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
  });
};

const handleGetAttachments = (): void => {
  apiGetReceiptAttachments(receipt.value.id).then((res: any) => {
    attachments.value = res.data.data;
    editReceiptModalVisible.value = true;
  });
};

const handleDeleteAttachment = (attachmentId: number): Promise<any> => {
  return apiDeleteReceiptAttachment(receipt.value.id, attachmentId).catch(
    () => $message?.error("Couldn't delete attachment!", 3)
  );
};
</script>
