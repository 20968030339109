<template>
  <div class="d-flex flex-row">
    <job-posting-board-column
      title="New"
      :job-postings="newJobPostings"
      :pagination="newJobPostingsPagination"
      :loading="loadingNewJobPostings"
      @load-more-job-postings="() => $emit('load-more-new-job-postings')"
    />
    <job-posting-board-column
      title="Ongoing"
      :job-postings="ongoingJobPostings"
      :pagination="ongoingJobPostingsPagination"
      :loading="loadingOngoingJobPostings"
      @load-more-job-postings="() => $emit('load-more-new-job-postings')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useJobPostingsStore, JobPostingsPaginationInterface } from "@/modules/job_postings/_store";
import JobPostingBoardColumn from "./JobPostingBoardColumn.vue";
import { JobPostingInterface } from "../types";

// Pinia
const jobPostingsStore = useJobPostingsStore();
const newJobPostings = computed<Array<JobPostingInterface>>(() => jobPostingsStore.newJobPostings);
const newJobPostingsPagination = computed<JobPostingsPaginationInterface>(
  () => jobPostingsStore.newJobPostingsPagination
);
const loadingNewJobPostings = computed<boolean>(() => jobPostingsStore.loadingNewJobPostings);
const ongoingJobPostings = computed<Array<JobPostingInterface>>(
  () => jobPostingsStore.ongoingJobPostings
);
const ongoingJobPostingsPagination = computed<JobPostingsPaginationInterface>(
  () => jobPostingsStore.ongoingJobPostingsPagination
);
const loadingOngoingJobPostings = computed<boolean>(
  () => jobPostingsStore.loadingOngoingJobPostings
);
</script>
