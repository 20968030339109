import axios from "@/network";
import { ReportPayloadInterface, SurveyPayloadInterface } from "../types";

export function apiGetReports(params: any, headers = {}) {
  const url = "/reports";
  return axios.get(url, { params, headers });
}

export function apiGetCalendarReports(month: string) {
  const url = `/reports/calendar`;
  const params = { month };
  return axios.get(url, { params });
}

export function apiGetPublicHolidays(month: string) {
  const url = `/reports/public_holidays`;
  const params = { month };
  return axios.get(url, { params });
}
export function apiGetAnnualPublicHolidays(year: string) {
  const url = `/reports/public_holidays`;
  const params = { year };
  return axios.get(url, { params });
}

export function apiStoreReport(newReport: ReportPayloadInterface) {
  const url = "/reports";
  const payload = {
    report: newReport,
    suppress_warnings: newReport.suppress_warnings,
  };
  payload.report.suppress_warnings = undefined;
  return axios.post(url, payload);
}

export function apiUpdateReport(reportId: number, updatedReport: ReportPayloadInterface) {
  const url = `/reports/${updatedReport.id}`;
  const payload = {
    report: updatedReport,
    suppress_warnings: updatedReport.suppress_warnings,
  };
  payload.report.suppress_warnings = undefined;
  return axios.patch(url, payload);
}

export function apiDestroyReport(reportId: number) {
  const url = `/reports/${reportId}`;
  return axios.delete(url);
}

export function apiLockUserReports(userId: number, lockingDate: string) {
  const url = `/users/${userId}/lock`;
  const payload = { user: { report_locking_date: lockingDate } };
  return axios.patch(url, payload);
}

export function apiStoreSurveyResponse(response: SurveyPayloadInterface) {
  const url = "/surveys";
  return axios.post(url, response);
}
