import axios from "@/network";

export function apiGetExperiences(user_id: number, experience_type: string): Promise<any> {
  const url = `users/${user_id}/${experience_type}`;
  return axios.get(url);
}

export function apiGetExperienceDescriptionFormAnswers(
  user_id: number,
  experience_id: number,
  experience_type: string
): Promise<any> {
  const url = `users/${user_id}/${experience_type}/${experience_id}/description_forms`;
  return axios.get(url);
}
