<template>
  <div v-if="canActOnRole()" class="mt-2">
    <strong v-if="withTitle" class="mr-2">Actions:</strong>
    <a-tooltip v-if="canFillInForm()">
      <template #title> Fill in a new role description form </template>
      <a-icon
        v-if="role.editable"
        type="file-text"
        class="action-default"
        @click="
          (e) => {
            $emit('show-answer-role-description-form');
            e.stopPropagation();
          }
        "
      />
    </a-tooltip>
    <a-divider v-if="canEdit()" type="vertical" />
    <a-tooltip>
      <template #title> Edit role information </template>
      <a-icon
        v-if="role.editable"
        type="edit"
        class="action-default"
        @click="
          (e) => {
            $emit('show-edit-role-modal');
            e.stopPropagation();
          }
        "
      />
    </a-tooltip>
    <a-divider v-if="canDelete()" type="vertical" />
    <a-tooltip>
      <template #title> Delete role </template>
      <a-icon
        v-if="role.deletable"
        type="delete"
        class="action-danger"
        @click="
          (e) => {
            $emit('show-delete-role-modal');
            e.stopPropagation();
          }
        "
      />
    </a-tooltip>
  </div>
</template>

<script setup lang="ts">
import { ProfileInterface } from "@/modules/authentication/types";
import { RoleInterface } from "../types";

// Props
const props = defineProps({
  role: { type: Object as () => RoleInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  withTitle: { type: Boolean, default: false },
});

// Component methods
const canActOnRole = (): boolean => {
  return canFillInForm() || canEdit() || canDelete();
};

const canFillInForm = (): boolean => {
  return props.role.user.id === props.profile.id;
};

const canEdit = (): boolean => {
  return props.role.user.id === props.profile.id;
};

const canDelete = (): boolean => {
  return props.role.deletable && props.role.editable;
};
</script>
